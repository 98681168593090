import React, { Component } from 'react';

import Auxillary from '../Auxillary/Auxillary';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import Header from '../../components/Header/Header';
import HeroImage from '../../components/HeroImage/HeroImage';
import Stats from '../../components/Stats/Stats';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import SideBar from '../../components/SideBar/SideBar';
import Banners from '../../components/Banners/Banners';
import Footer from '../../components/Footer/Footer';
import CookieDisclaimer from '../../components/CookieDisclaimer/CookieDisclaimer';
import BottomCallUs from '../../components/BottomCallUs/BottomCallUs';
import Cookies from 'js-cookie';

import { Route, Switch } from 'react-router-dom';
import Home from '../../components/Routes/Home';
import About from '../../components/Routes/About';
import Services from '../../components/Routes/Services';
import LEV from '../../components/Routes/Services/LEV';
import Portfolio from '../../components/Routes/Portfolio';
import Blog from '../../components/Routes/Blog';
import Contact from '../../components/Routes/Contact';
import Testimonials from '../../components/Routes/Testimonials';
import CookiePolicy from '../../components/Routes/CookiePolicy'
import Privacy from '../../components/Routes/Privacy';
import NotFound from '../../components/Routes/NotFound';

import Ductwork from '../../components/Routes/Portfolio/Ductwork';
import Hubron from '../../components/Routes/Portfolio/Hubron';
import Bench from '../../components/Routes/Portfolio/Bench';
import Fume from '../../components/Routes/Portfolio/Fume';
import Feed from '../../components/Routes/Portfolio/Feed';
import Microblade from '../../components/Routes/Portfolio/Microblade';
import Inspection from '../../components/Routes/Portfolio/Inspection';
import Hood from '../../components/Routes/Portfolio/Hood';
import Dust from '../../components/Routes/Portfolio/Dust';
import Installation from '../../components/Routes/Portfolio/Installation';
import Warburtons from '../../components/Routes/Portfolio/Warburtons';
import Video from '../../components/Routes/Portfolio/Video';
import Bakery from '../../components/Routes/Portfolio/Bakery';

import Wood from '../../components/Routes/Blog/Wood';
import Soldering from '../../components/Routes/Blog/Soldering';
import Equipment from '../../components/Routes/Blog/Equipment';
import Clean from '../../components/Routes/Blog/Clean';
import Manufacturing from '../../components/Routes/Blog/Manufacturing';
import Pollution from '../../components/Routes/Blog/Pollution';
import Postponed from '../../components/Routes/Blog/Postponed';

class Layout extends Component {
    state = {
        showSideMenu: false,
        acceptedCookies: Cookies.get('cookiePolicy') === "true",
        breadcrumbList: [{ key: 1, link: "/", title: "Home" }],
        mainImageList: [],
        mainImageKey: 1,
        showContactUsButton: true,
        showFindOutMoreButton: true,
        useLEVHeader: false,
        heroSellingPoints: [{ key: 1, text: "40 years of LEV system experience" }, { key: 2, text: "Professional installation & testing" }, {key: 3, text: "Highly reputable service guaranteed"}]
    }

    sideMenuClosedHandler = () => {
        this.setState({showSideMenu: false});
    }

    sideMenuToggleHandler = () => {
        this.setState((prevSate) => { //setting state when rely on old state
            return {showSideMenu: !prevSate.showSideMenu};
        });
    }

    //only called on specific routes then reset on breadcrumbChangeHandler when route changed
    hideContactUsButton = () => {
        this.setState({showContactUsButton: false});
    }

    hideFindOutMoreButton = () => {
        this.setState({showFindOutMoreButton: false});
    }

    useLEVHeaderHandler = () => {
        this.setState({ useLEVHeader: true });
    }
    //------------------------------------------------------------------------

    acceptedCookiesHandler = (e) => {
        this.setState({acceptedCookies: true});

        const exdays = 7; 
        const cookieName = "cookiePolicy";
        const cookieVal = "true";
        const d = new Date();
        
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        //cannot set httponly for cookies in JS (chrome won't add secure cookie when local)
        document.cookie = cookieName + '=' + cookieVal + '; expires=' + d.toUTCString() + '; path=/; secure';
    }

    breadcrumbChangeHandler = (newBreadcrumbList) => {
        this.setState({ breadcrumbList: newBreadcrumbList, showContactUsButton: true, showFindOutMoreButton: true, useLEVHeader: false });
    }

    heroImageChangeHandler = (newHeroImageList) => {
        this.setState((prevState) => {
            //update mainImageKey here which will force heroImage component to reload properly with key=
            return { mainImageList: newHeroImageList, mainImageKey: prevState.mainImageKey + 1 }
        });
    }

    render () {
        const images = require.context('../../assets/images', true);

        const blogList = [
            { key: 1, title: "Air Pollution Plunges Across UK During the First Two Weeks of Lockdown", imageSrc: images("./extraction-bench-ducting-small.jpg").default, imageAlt: "Extraction Bench Ducting", link: "/blog/air-pollution-plunges-across-uk-during-the-first-two-weeks-of-lockdown", preview: "With lockdowns reducing human activity..." },
            { key: 2, title: "Clean Air Day 2020 Postponed To 8th October", imageSrc: images("./extraction-cylinders-small.jpg").default, imageAlt: "Extraction Cylinders", link: "/blog/clean-air-day-2020-postponed-to-8th-october", preview: "In light of recent events with COVID-19, the Clean Air Day team..." },
            { key: 3, title: "Latest Manufacturing Industry Figures", imageSrc: images("./ductwork-system-small.jpg").default, imageAlt: "", link: "/blog/latest-manufacturing-industry-figures", preview: "UK firm Midland Pallet Trucks – which provides..." },
            { key: 4, title: "Clean Air Day", imageSrc: images("./ventilation-unit-small.jpg").default, imageAlt: "Ventilation Unit", link: "/blog/clean-air-day", preview: "All set for Clean Air Day on Thursday? You are part of something..." },
            { key: 5, title: "Extend The Life Of Your Equipment", imageSrc: images("./exhaust-workbench-small.jpg").default, imageAlt: "Exhaust Workbench", link: "/blog/extend-equipment-life", preview: "Dust extraction systems actually lower..." },
            { key: 6, title: "Rosin-Based Soldering Fumes", imageSrc: images("./correct-ducting-small.jpg").default, imageAlt: "Adequate Ducting", link: "/blog/rosin-based-soldering-fumes", preview: "One example of how rosin-based soldering..." },
            { key: 7, title: "Safely Remove Wood Dust", imageSrc: images("./flameless-vent-system-small.jpg").default, imageAlt: "Flameless Vent System", link: "/blog/safely-remove-wood-dust", preview: "Companies must protect their employees..." }
        ];

        const portfolioList = [
            { key: 1, title: "Ductwork For Deburring Machine", imageSrc: images("./ductwork-system-small.jpg").default, imageAlt: "Ductwork System", link: "/portfolio/ductwork-for-deburring-machine", preview: "New ductwork design & supply for deburring machine..." },
            { key: 2, title: "Hubron International", imageSrc: images("./correct-ducting-small.jpg").default, imageAlt: "Adequate Ducting", link: "/portfolio/hubron-international-limited", preview: "Existing LEV hood design inadequate and allocated air volume insufficient..." },
            { key: 3, title: "Dust Extraction Bench", imageSrc: images("./extraction-bench-ducting-small.jpg").default, imageAlt: "Extraction Bench Ducting", link: "/portfolio/bespoke-design-dust-extraction-bench", preview: "Bespoke design Dust Extraction bench reducing dust levels in the workplace..." },
            { key: 4, title: "Fume Extraction Serving Ovens", imageSrc: images("./fume-extraction-ducting-small.jpg").default, imageAlt: "Fume Extraction Ducting", link: "/portfolio/fume-extraction", preview: "Check out this fume extraction project in Knowsley, serving..." },
            { key: 5, title: "Animal Feed Project", imageSrc: images("./extraction-cylinders-small.jpg").default, imageAlt: "Extraction Cylinders", link: "/portfolio/animal-feed-project", preview: "Dust extraction system serving New packing line..." },
            { key: 6, title: "Microblade", imageSrc: images("./dust-ventilation-small.jpg").default, imageAlt: "Dust Ventilation System", link: "/portfolio/microblade-limited", preview: "Existing LEV System clients dust monitoring..." },
            { key: 7, title: "ALS Inspection", imageSrc: images("./fume-extractor-system-small.jpg").default, imageAlt: "Fume Extractor System", link: "/portfolio/als-inspection-uk-ltd", preview: "Existing ducting layout for ALS Inspection..." },
            { key: 8, title: "New Design Hood & Ducting", imageSrc: images("./hood-and-ducting-small.jpg").default, imageAlt: "Hood And Ducting", link: "/portfolio/new-design-hood-ducting", preview: "New design hood & ducting..." },
            { key: 9, title: "A Total Dust Extraction Solution", imageSrc: images("./ventilation-unit-small.jpg").default, imageAlt: "Ventilation Unit", link: "/portfolio/a-total-dust-extraction-solution", preview: "LEV Designs was challenged to design..." },
            { key: 10, title: "LEV Installation in Sheffield", imageSrc: images("./exhaust-workbench-small.jpg").default, imageAlt: "Exhaust Workbench", link: "/portfolio/lev-installation", preview: "LEV & Air Movement Designs Ltd has..." },
            { key: 11, title: "The Warburton Crumpet Line", imageSrc: images("./flameless-vent-system-small.jpg").default, imageAlt: "Flameless Vent System", link: "/portfolio/warburtons", preview: "When you experience the huge benefits..." },
            { key: 12, title: "LEV System Video", imageSrc: images("./lev-system-video-small.jpg").default, imageAlt: "LEV System Video", link: "/portfolio/lev-system-video", preview: "Shiny, new, clean and efficient. All LEV installations are built to deliver..." },
            { key: 13, title: "Leading Baking Manufacturer", imageSrc: images("./reverse-pulse-filter-units-small.jpg").default, imageAlt: "Leading Baking Manufacturer", link: "/portfolio/bakery-manufacturer", preview: "A leading manufacturer who supplies bakeries..." }
        ];

        return (
            
            <Auxillary>
                <Header menuToggleClicked={this.sideMenuToggleHandler}
                        openSideMenu={this.state.showSideMenu}
                        sideMenuClosed={this.sideMenuClosedHandler}/>
                <HeroImage key={this.state.mainImageKey} images={this.state.mainImageList} useLEVHeader={this.state.useLEVHeader} heroSellingPoints={this.state.heroSellingPoints}
                           showContactUsButton={this.state.showContactUsButton} showFindOutMoreButton={this.state.showFindOutMoreButton} />
                <Stats />
                <Breadcrumb breadcrumbList={this.state.breadcrumbList} />
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-7 col-xl-8">
                                <main>
                                    <article>
                                        <ScrollToTop />
                                        <Switch>
                                            <Route path="/" exact>
                                                <Home setLocation={this.breadcrumbChangeHandler} link="/" title="Dust, Fume And Ventilation Systems" setMainImage={this.heroImageChangeHandler} description="LEV Designs offer independent engineered solutions based on your unique requirements. Our services include LEV testing and bespoke ventilation designs." />
                                            </Route>
                                            <Route path="/about" exact>
                                                <About setLocation={this.breadcrumbChangeHandler} link="/about" title="About Us" setMainImage={this.heroImageChangeHandler} description="Contact LEV Designs who are the dust, fume and ventilation experts. With over 40 years experience, we ensure you have a system to make your business fit for purpose." hideFindOutMoreButton={this.hideFindOutMoreButton} />
                                            </Route>
                                            <Route path="/services" exact>
                                                <Services setLocation={this.breadcrumbChangeHandler} link="/services" title="Services" setMainImage={this.heroImageChangeHandler} description="We offer reputable services for LEV testing which includes maintenance and bespoke implementation of bespoke dust, fume and ventilation systems." />
                                            </Route>
                                            <Route path="/services/lev-testing" exact>
                                                <LEV setLocation={this.breadcrumbChangeHandler} link="/services/lev-testing" title="LEV Testing" setMainImage={this.heroImageChangeHandler} description="Specialist LEV testing services across the North West with thorough examinations. Rely on technicians with 40 years industry experience." useLEVHeaderHandler={this.useLEVHeaderHandler} />
                                            </Route>
                                            <Route path="/portfolio" exact>
                                                <Portfolio setLocation={this.breadcrumbChangeHandler} link="/portfolio" title="Portfolio" portfolioList={portfolioList} setMainImage={this.heroImageChangeHandler} description="A portfolio showing samples of the detailed work we have carried out for our clients to help minimise the risks that dust and fumes can cause in the workplace." />
                                            </Route>
                                            <Route path="/blog" exact>
                                                <Blog setLocation={this.breadcrumbChangeHandler} link="/blog" title="Blog" blogList={blogList} setMainImage={this.heroImageChangeHandler} description="Latest news regarding LEV testing and ventilation systems across the industry. Tips on maintaining clean air throughout your environment can help keep your workforce healthy." />
                                            </Route>
                                            <Route path="/contact" exact>
                                                <Contact setLocation={this.breadcrumbChangeHandler} link="/contact" title="Contact Us" setMainImage={this.heroImageChangeHandler} description="Speak to our specialist team for industry leading advice on LEV design systems, testing and layout. No job is too big or too small for us to tackle." hideContactUsButton={this.hideContactUsButton} />
                                            </Route>
                                            <Route path="/cookies" exact>
                                                <CookiePolicy setLocation={this.breadcrumbChangeHandler} link="/cookies" title="Cookie Policy" setMainImage={this.heroImageChangeHandler} description="The cookie policy for LEV Designs outlining what cookies are, how they are used throughout the website and how you can manage them on your device." />
                                            </Route>
                                            <Route path="/privacy" exact>
                                                <Privacy setLocation={this.breadcrumbChangeHandler} link="/privacy" title="Privacy Policy" setMainImage={this.heroImageChangeHandler} description="The privacy policy for LEV Designs and information on how we use / process your personal data. Your individual rights and GDPR details from ICO are covered here." />
                                            </Route>
                                            <Route path="/testimonials" exact>
                                                <Testimonials setLocation={this.breadcrumbChangeHandler} link="/testimonials" title="Testimonials" setMainImage={this.heroImageChangeHandler} description="We take a lot of pride in the solutions we provide and this is shown with the feedback received from our customers who appreciate our work." />
                                            </Route>
                                            {/* Portfolio */}
                                            <Route path={portfolioList[0].link} exact>
                                                <Ductwork setLocation={this.breadcrumbChangeHandler} title={portfolioList[0].title} link={portfolioList[0].link} setMainImage={this.heroImageChangeHandler} description="An example of our specialist ductwork design and build for a deburring machine that we engineered for a client." />
                                            </Route>
                                            <Route path={portfolioList[1].link} exact>
                                                <Hubron setLocation={this.breadcrumbChangeHandler} title={portfolioList[1].title} link={portfolioList[1].link} setMainImage={this.heroImageChangeHandler} description="Hubron International asked for our help to install much needed LEV booths and correctly designed ducting in order to contain the excess dust created." />
                                            </Route>
                                            <Route path={portfolioList[2].link} exact>
                                                <Bench setLocation={this.breadcrumbChangeHandler} title={portfolioList[2].title} link={portfolioList[2].link} setMainImage={this.heroImageChangeHandler} description="We engineered a brand new dust extraction bench in order to achieve less dust levels in the building which now serves its purpose properly." />
                                            </Route>
                                            <Route path={portfolioList[3].link} exact>
                                                <Fume setLocation={this.breadcrumbChangeHandler} title={portfolioList[3].title} link={portfolioList[3].link} setMainImage={this.heroImageChangeHandler} description="The ultimate goal was to reduce hazardous fumes and remove them at source, creating a healthy working environment. Also reducing downtime and increasing productivity." />
                                            </Route>
                                            <Route path={portfolioList[4].link} exact>
                                                <Feed setLocation={this.breadcrumbChangeHandler} title={portfolioList[4].title} link={portfolioList[4].link} setMainImage={this.heroImageChangeHandler} description="We took on the Animal Feed Project to help reduce any dust particles collecting unnecessarily within the warehouse and ultimately guarantee better employee health." />
                                            </Route>
                                            <Route path={portfolioList[5].link} exact>
                                                <Microblade setLocation={this.breadcrumbChangeHandler} title={portfolioList[5].title} link={portfolioList[5].link} setMainImage={this.heroImageChangeHandler} description="At Microblade we were able to reduce dust monitoring results from 11.12 mg/m3 to 2.27 mg/m3 by implementing a new LEV side and downdraft workbench." />
                                            </Route>
                                            <Route path={portfolioList[6].link} exact>
                                                <Inspection setLocation={this.breadcrumbChangeHandler} title={portfolioList[6].title} link={portfolioList[6].link} setMainImage={this.heroImageChangeHandler} description="We were able to upgrade the fume extractor layout with an adequate solution at ALS Inspection to assist with better extraction without breaking the bank." />
                                            </Route>
                                            <Route path={portfolioList[7].link} exact>
                                                <Hood setLocation={this.breadcrumbChangeHandler} title={portfolioList[7].title} link={portfolioList[7].link} setMainImage={this.heroImageChangeHandler} description="We designed and constructed a new hood plus ducting for a high speed mixer to enhance the extraction process. Get in touch for your LEV design consultation." />
                                            </Route>
                                            <Route path={portfolioList[8].link} exact>
                                                <Dust setLocation={this.breadcrumbChangeHandler} title={portfolioList[8].title} link={portfolioList[8].link} setMainImage={this.heroImageChangeHandler} description="LEV Designs implemented a dust extraction system including a TDS 24 reverse jet filter, centrifugal fan set and galvanised mild steel interconnecting ductwork" />
                                            </Route>
                                            <Route path={portfolioList[9].link} exact>
                                                <Installation setLocation={this.breadcrumbChangeHandler} title={portfolioList[9].title} link={portfolioList[9].link} setMainImage={this.heroImageChangeHandler} description="We undertook the LEV examination and testing of all the production areas for a manufacturing facility in Sheffield. The improvements yielded amazing results." />
                                            </Route>
                                            <Route path={portfolioList[10].link} exact>
                                                <Warburtons setLocation={this.breadcrumbChangeHandler} title={portfolioList[10].title} link={portfolioList[10].link} setMainImage={this.heroImageChangeHandler} description="Warburtons were over the moon with the new LEV system we installed which received high praise from their production department. Accomplishing an efficient solution." />
                                            </Route>
                                            <Route path={portfolioList[11].link} exact>
                                                <Video setLocation={this.breadcrumbChangeHandler} title={portfolioList[11].title} link={portfolioList[11].link} setMainImage={this.heroImageChangeHandler} description="LEV Designs offers a reliable and cost effective installation for any dust or fume extraction with the ultimate aim of improving the safety of your working environment." />
                                            </Route>
                                            <Route path={portfolioList[12].link} exact>
                                                <Bakery setLocation={this.breadcrumbChangeHandler} title={portfolioList[12].title} link={portfolioList[12].link} setMainImage={this.heroImageChangeHandler} description="We created a bespoke LEV plant installation design to improve the air flow for a leading UK bakery manufacturer. Consisting of laminar flow booth workstations and filters." />
                                            </Route>
                                            {/* Blog */}
                                            <Route path={blogList[0].link} exact>
                                                <Pollution setLocation={this.breadcrumbChangeHandler} title={blogList[0].title} link={blogList[0].link} setMainImage={this.heroImageChangeHandler} description="During lockdown, caused by the global COVID19 pandemic, air pollution plummeted in London by 34.29% following the drop in human activity across the United Kingdom." />
                                            </Route>
                                            <Route path={blogList[1].link} exact>
                                                <Postponed setLocation={this.breadcrumbChangeHandler} title={blogList[1].title} link={blogList[1].link} setMainImage={this.heroImageChangeHandler} description="Clean Air Day 2020, which is the largest air pollution campaign in the UK, has been postponed because of the COVID19 pandemic until later in the year." />
                                            </Route>
                                            <Route path={blogList[2].link} exact>
                                                <Manufacturing setLocation={this.breadcrumbChangeHandler} title={blogList[2].title} link={blogList[2].link} setMainImage={this.heroImageChangeHandler} description="Manufacturing hits its lowest period of growth for two years, negatively impacting export and workforce across the industry. Correlating with drops across Europe." />
                                            </Route>
                                            <Route path={blogList[3].link} exact>
                                                <Clean setLocation={this.breadcrumbChangeHandler} title={blogList[3].title} link={blogList[3].link} setMainImage={this.heroImageChangeHandler} description="Clean Air Day calls on schools, businesses and local communities to come together to fight air pollution (the largest environmental health risk we currently face)." />
                                            </Route>
                                            <Route path={blogList[4].link} exact>
                                                <Equipment setLocation={this.breadcrumbChangeHandler} title={blogList[4].title} link={blogList[4].link} setMainImage={this.heroImageChangeHandler} description="Lower your business housekeeping fees by removing unwanted dust particles from your premises. An effective system will also minimise costly equipment breakdowns." />
                                            </Route>
                                            <Route path={blogList[5].link} exact>
                                                <Soldering setLocation={this.breadcrumbChangeHandler} title={blogList[5].title} link={blogList[5].link} setMainImage={this.heroImageChangeHandler} description="Soldering fumes can be hazardous and dangerous for workers in the industry. Failing to comply with the regulations can often trigger fines in the hundreds of thousands." />
                                            </Route>
                                            <Route path={blogList[6].link} exact>
                                                <Wood setLocation={this.breadcrumbChangeHandler} title={blogList[6].title} link={blogList[6].link} setMainImage={this.heroImageChangeHandler} description="Wood dust is a serious issue when it comes to employee health. LEV Designs can help control your environment by installing an expertly managed wood dust extraction system." />
                                            </Route>
                                            <Route path="*">
                                                <NotFound setLocation={this.breadcrumbChangeHandler} title="404 Not Found" setMainImage={this.heroImageChangeHandler}/>
                                            </Route>
                                        </Switch>
                                    </article>
                                </main>
                            </div>
                            <div className="col-12 offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-0 col-lg-5 col-xl-4">
                                <SideBar showContactUsButton={this.state.showContactUsButton}  />
                            </div>
                        </div>
                    </div>
                </div>
                <Banners />
                <Footer />
                <CookieDisclaimer showCookiesMessage={!this.state.acceptedCookies}
                                  acceptedCookiesClick={this.acceptedCookiesHandler} />
                <BottomCallUs />
            </Auxillary>
        )
    }
};

export default Layout;