import React from 'react';

import classes from './Stats.module.css';

const stats = () => {
    return (
        <div className={classes.Stats}>
            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-10 offset-1 col-sm-12 offset-sm-0">
                            <div className="row">
                                <div className="col-12">
                                    <h2>Engineers with <strong>your interests</strong> at heart</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-6 col-lg-3">
                                    <p><span>987</span>Tests Performed</p>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3">
                                    <p>System Repairs<span>56</span></p>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3">
                                    <p><span>75</span>LEV Installations</p>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3">
                                    <p className={classes.DarkVersion}>Testing From<span>£190</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default stats;