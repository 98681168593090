import React from 'react';
import LazyLoad from 'react-lazyload';

const image = (props) => (
    (
        props.useLazyLoad == null ? 
                <LazyLoad>
                    <img src={props.src} alt={props.alt} className={props.classList == null ? null : props.classList.join(" ")} onClick={props.imageClicked == null ? null : props.imageClicked} />
                </LazyLoad>
            :
                <img src={props.src} alt={props.alt} className={props.classList == null ? null : props.classList.join(" ")} onClick={props.imageClicked == null ? null : props.imageClicked} />

    )
);

export default image;