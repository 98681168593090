import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Microblade extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/portfolio", title: "Portfolio" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./lev-workbench.jpg").default, alt: "LEV Workbench" }]);
    }

    render () {
        const images = require.context('../../../assets/images', true);

        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>Existing LEV System clients dust monitoring results: 11.12 mg/m3</p>
                <p>New bespoke designed LEV Side / Downdraft Workbench</p>
                <p><img src={images("./dust-ventilation.jpg").default} alt="Dust Ventilation" className="img-fluid"/></p>
                <p><img src={images("./dust-system-employee.jpg").default} alt="Dust System Employee" className="img-fluid"/></p>
                <p>New LEV System clients dust monitoring results: 2.27 mg/m3</p>
                <p><img src={images("./extraction-bench.jpg").default} alt="Extraction Bench" className="img-fluid"/></p>
                <p><img src={images("./microblade.jpg").default} alt="Microblade Dust System" className="img-fluid"/></p>
            </Auxillary>
        )
    }
    
};

Microblade.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Microblade;