import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Dust extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/portfolio", title: "Portfolio" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./dust-extraction-solution.jpg").default, alt: "Dust Extraction Solution" }]);
    }

    render () {
        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>LEV Designs was challenged to design a new dust extraction system to serve the three machines below:</p>
                <p>Proposed Dust Extraction System to Serve Beam Saw &amp; Edge Bander Machine Area</p>
                <table className="table">
                    <tbody>
                        <tr>
                            <td>Air Volume:</td>
                            <td>19,000m3/hr</td>
                        </tr>
                        <tr>
                            <td>Dust:</td>
                            <td>Fibre Glass &amp; Wood</td>
                        </tr>
                        <tr>
                            <td>Dust Explosion Class:</td>
                            <td>St1</td>
                        </tr>
                        <tr>
                            <td>Temperature:</td>
                            <td>20°C</td>
                        </tr>
                    </tbody>
                </table>
                <p><strong>Installed System Comprised of:</strong></p>
                <h2>Filter – Supplied by Donaldson Filtration</h2>
                <p>To handle this duty we propose a TDS 24 reverse jet filter complete with Ultra-Web filter media, built in pre-separator, magnehelic gauge, hopper and support, wood waste rotary valve, and IPC ΔP reverse jet sequential controller as outlined in the attached filter specification.</p>
                <h2>Centrifugal Fan Set – Supplied by RHF Fans</h2>
                <p>To exhaust the air volume of 19,009 m3hr against the static losses imposed by the system design we have selected a centrifugal fan directly driven by a 30kw.</p>
                <h2>Ductwork – Supplied by Truduct Product</h2>
                <p>We include for one new correctly design  set of galvanised mild steel interconnecting ductwork between filter unit and new non return blast damper which be located approximately 3 m away from the filter unit.</p>
                <p>This section of ductwork will be manufactured from heavier gauge ductwork as recommended by guidelines.</p>
                <p>From the blast damper, we propose to run correctly size duct to each of the three station and incorporating manually operated dampers to allow for the system balance after installation.</p>
                <p>The exhaust ductwork would be manufactured from 18’s  g galvanised mild steel and sized to maintain a minimum conveying velocity of 18 m/s to ensure no dust settlement within the duct systems.</p>
                <p>Non Return Explosion Blast Damper</p>
                <p>Included: this is a recommendation to be incorporated into the system.</p>
                <p><strong>Installation by LEV &amp; Air Movement Designs Ltd</strong></p>
                <p><strong>Commissioning &amp; Initial LEV Testing</strong></p>
                <p>We included commissioning and LEV examination/testing over a one day period to establish initial airflow rates, volumes and system pressures including quantitative and qualitative testing with a record of each being logged to comply with the latest HSE guidance (HSG258)</p>
            </Auxillary>
        )
    }
    
};

Dust.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Dust;