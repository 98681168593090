import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Ductwork extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/portfolio", title: "Portfolio" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./lev-ductwork-systems.jpg").default, alt: "LEV Ductwork Systems" }]);
    }

    render () {
        const images = require.context('../../../assets/images', true);

        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>New ductwork design &amp; supply for deburring machine</p>
                <p><img src={images("./ductwork-system.jpg").default} alt="Ductwork System" className="img-fluid"/></p>
                <p><img src={images("./minimise-dust-levels.jpg").default} alt="Deburring Maachine" className="img-fluid"/></p>
            </Auxillary>
        )
    }
    
};

Ductwork.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Ductwork;