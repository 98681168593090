import React, { Component } from 'react';
import Head from "../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../hoc/Auxillary/Auxillary';

class AboutUs extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" }, { key: 2, link: "/about", title: "About Us" }]);
        const images = require.context('../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./dust-ventilation-system.jpg").default, alt: "Bespoke LEV Extraction System" }]);
        this.props.hideFindOutMoreButton();
    }

    render () {
        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>About Us</h1>
                <p>Clean air really does mean a healthy workforce. LEV Designs offers sensible advice, based on experience in the business and through running diagnostic tests on your site, to make sure you have in place what you need. We’ll ensure you have a system or series of solutions to make your business fit for purposes now and into the future. If you’re looking to expand, contract or diversify, we are your partner to guarantee you get the best possible systems to meet your needs.</p>
                <p>According to HSE regulations, the levels of indoor air pollutants could be anywhere from five to 100 times higher than outdoor levels. So, to avoid triggering or aggravating allergies, your company could consider the fabrics used on furniture in the office. You could try to use low- or non-toxic paints or bring in plants to counteract any pollutants that do exist. There are so many solutions to getting, not just OK, air quality, but really great air quality!</p>
                <ul>
                    <li>LEV Designs offers dedicated independent engineered solutions.</li>
                    <li>Experts in dust, fume and general ventilation systems.</li>
                    <li>Key services include: LEV Testing / Dust, Fume &amp; Ventilation Systems.</li>
                    <li>Our plant maintenance includes replacement filter elements and spares.</li>
                    <li>We also give advice on existing LEV plant layout and LEV testing.</li>
                    <li>No job is too big or too small.</li>
                    <li>We have the expertise and know-how to really deliver benefits.</li>
                    <li>The skill-base and reputation, to deliver the best for you every time.</li>
                    <li>Build safety, efficiency and cost control into your mix ONLY with LEV Designs!</li>
                </ul>
                <p>Having an experienced sales person as a project runner with all the technical knowledge to deliver for you means you get no nonsense and no delay! It’s a lean business and all LEV Designs’ customers get far more for their money. Great physical solutions, training, troubleshooting support, breakdown cover, repairs, maintenance, upgrades and new additions – it’s all covered with one phone call.</p>
            </Auxillary>
        )
    }
    
};

AboutUs.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default AboutUs;