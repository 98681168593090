import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      //scroll to top of page when route changes
      window.scrollTo(0, 0);

      //send page view to GA3 analytics to track route changes
      window.gtag('event', 'page_view', {
          // page_title: '` + props.title + `',
          page_location: window.location.href,
          page_path: window.location.href.replace(window.location.origin, ""),
          send_to: 'UA-208898542-1'
      });
    });
    return () => {
      unlisten();
    }
  }, [history]);

  return (null);
}

export default withRouter(ScrollToTop);