import React from 'react';

import classes from './BottomCallUs.module.css';

const bottomCallUs = () => {
    return (
        <section className={classes.BottomCallUs}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6 text-center text-left-sm">
                        <a href="tel:01617631937" className="btn telno" title="Speak To Our Team">Call 0161 763 1937</a>
                    </div>
                    <div className="d-none d-sm-block col-sm-6 text-right">
                        <a href="mailto:info@levdesigns.co.uk" className="btn emailaddress" title="Email Us">Email info@levdesigns.co.uk</a>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default bottomCallUs;