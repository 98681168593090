import React from 'react';

import classes from './Process.module.css';

import Image from '../../UI/Image/Image';

const process = () => {
    const images = require.context('../../../assets/images', true);
    return (
        <div className={classes.Process}>
            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-8 offset-2 col-sm-12 offset-sm-0 col-md-10 offset-md-1 col-lg-12 offset-lg-0">
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <h2>LEV extraction systems made <strong>simple</strong> and <strong>affordable</strong></h2>
                                </div>
                                <div className={classes.CircleWrapper + " col-12 col-sm-6 col-lg-3"}>
                                    <div className={classes.Circle}>
                                        <Image src={images("./speech-bubble.png").default} alt="Speech Bubble" />
                                        <p>Discuss your needs with us</p>
                                    </div>
                                </div>
                                <div className={classes.CircleWrapper + " col-12 mt-4 col-sm-6 mt-sm-0 col-lg-3"}>
                                    <div className={classes.Circle}>
                                        <Image src={images("./calendar.png").default} alt="Calendar" />
                                        <p>Schedule an appointment</p>
                                    </div>
                                </div>
                                <div className={classes.CircleWrapper + " col-12 mt-4 col-sm-6 col-lg-3 mt-lg-0"}>
                                    <div className={classes.Circle}>
                                        <Image src={images("./trophy.png").default} alt="Trophy" />
                                        <p>Expert technician assessment</p>
                                    </div>
                                </div>
                                <div className={classes.CircleWrapper + " col-12 mt-4 col-sm-6 col-lg-3 mt-lg-0"}>
                                    <div className={classes.Circle}>
                                        <Image src={images("./badge.png").default} alt="Badge" />
                                        <p>Correct solution guaranteed</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default process;