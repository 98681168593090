import React from 'react';
import Helmet from "react-helmet";

const head = (props) => (
    <Helmet>
        <title>{props.title + " | LEV Designs"}</title>
        {props.description != null ? <meta name="description" content={props.description} /> : null }
        {props.link != null ? <link href={"https://www.levdesigns.co.uk" + props.link} rel="canonical" /> : null}
        {props.link != null ? <link rel="alternate" href={"https://www.levdesigns.co.uk" + props.link} hreflang="en-gb" /> : null}
    </Helmet>
);

export default head;