import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Soldering extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/blog", title: "Blog" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./soldering-fume-extraction.jpg").default, alt: "Soldering Fume Extraction" }]);
    }

    render () {
        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>One example of how rosin-based soldering fumes can be hazardous, if you fail to comply with the Control of Substances Hazardous to Health Regulations 2002 (COSHH), is listed below.</p>
                <p>It's a story about a company who failed to protect one of its employees – who worked as a solderer. In his employment with them between 1995 and 2004, he was exposed to rosin-based solder fumes on a daily basis.</p>
                <p>Back in 1999, this solderer’s health seriously began to deteriorate. And from 1999 onwards, the employee started taking more and more time off work, due to his difficulties with breathing. The solderer’s company had failed to ensure there was any (let alone adequate) fume extraction equipment at the point the fumes were being generated. The company essentially failed to put adequate control measures in place to ensure the removal of the rosin-based soldering fumes from the air that this employee breathed.</p>
                <p>The result was that the company was fined £100,000 with and additional £30,000 in costs.</p>
                <p>More: <a href="http://www.hse.gov.uk/pubns/indg249.pdf" target="_blank" rel="nofollow noreferrer">http://www.hse.gov.uk/pubns/indg249.pdf</a></p>
            </Auxillary>
        )
    }
    
};

Soldering.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Soldering;