import React, { Component } from 'react';
import Head from "../Head/Head";
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import Auxillary from '../../hoc/Auxillary/Auxillary';

class Services extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" }, { key: 2, link: "/services", title: "Services" }]);
        const images = require.context('../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./healthy-working-environment.jpg").default, alt: "Healthy Working Environment" }]);
    }

    render () {
        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>Services</h1>
                <h2>LEV Designs: Key Services</h2>
                <h3>1) <NavLink to="/services/lev-testing" title="LEV Testing">LEV Testing</NavLink></h3>
                <p>By law all employees must ensure that thorough examination and testing of their "protective" LEV is carried out at least every 14 months (unless otherwise stipulated). Our engineers are qualified to British Occupational Hygiene Society (BOHS) P601 and our testing complies fully with HGE Guidance HSG258.</p>
                <h3>2) Independent Design On New LEV Systems</h3>
                <p>Most companies' project / engineering departments have been reduced to a minimum therefore do not have the resources or time to evaluate projects efficiently. We are independent from all major manufactures and therefore can offer unbiased recommendations and solutions for your specific requirements.</p>
                <h3>3) Advice on Existing LEV Plant layout</h3>
                <p>A company installs LEV systems for specific requirements, but over the years the production plant layout alters and therefore the performance of the exhaust system is greatly affected. We can assess your present plant layout and recommend / redesign the system to achieve the best performance from the existing equipment.</p>
                <h3>4) New / Existing Ductwork Installation</h3>
                <p>Whilst most local sheet metal / fabricators carry out this work, we believe based on experience, it is critical to receive the correct advice when installing new ductwork, as many mistakes can prove costly. (i.e. by just adding new connections, this can affect the performance and efficiency of your exhaust plant).</p>
                <h3>5) Replacement Filter Elements and Spares</h3>
                <p>We can supply replacement filter elements at a cost saving of approximately 15 – 20% off original equipment manufacturer and are able to provide advice on spares / spares register.</p>
                <h3>6) Plant Maintenance</h3>
                <p>We can set up a service agreement contract where one of our senior engineer’s will visit site on a regular basis to inspect, service and fit replacement parts to maintain the exhaust plant in good working order.</p>
            </Auxillary>
        )
    }
    
};

Services.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Services;