import React from 'react';

import classes from './SideBar.module.css';
import SellingPoints from '../../components/SideBar/SellingPoints/SellingPoints';
import ContactForm from '../../components/ContactForm/ContactForm';

const sideBar = (props) => (
    <aside className={classes.SideBar}>
        <div className="row">
            <div className="col-12">
                <SellingPoints />
            </div>
            <div className="col-12 grey-bgd mt-4">
                <ContactForm fullWidth={true}/>
            </div>
        </div>
    </aside>
);

export default sideBar;