import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Feed extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/portfolio", title: "Portfolio" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./ventilation-system.jpg").default, alt: "Ventilation System" }]);
    }

    render () {
        const images = require.context('../../../assets/images', true);

        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>Dust extraction system serving new packing line – Animal Feed Project</p>
                <p><img src={images("./extraction-cylinders.jpg").default} alt="Extraction Cyclinders" className="img-fluid"/></p>
                <p><img src={images("./lev-extraction.jpg").default} alt="LEV Extraction" className="img-fluid"/></p>
            </Auxillary>
        )
    }
    
};

Feed.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Feed;