import React from 'react';

import classes from './SellingPoints.module.css';

const sellingPoints = () => (
    <aside className={classes.SellingPoints}>
        <ul>
            <li><span>✓</span> LEV testing expertise</li>
            <li><span>✓</span> Expert ventilation systems</li>
            <li><span>✓</span> Reputable skill-base</li>
            <li><span>✓</span> 40 years experience</li>
            <li><span>✓</span> Cost-effective solutions</li>
            <li><span>✓</span> Invaluable support</li>
        </ul>
    </aside>
);

export default sellingPoints;