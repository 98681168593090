import React from 'react';

import Auxillary from '../../../../hoc/Auxillary/Auxillary';
import Backdrop from '../../../UI/Backdrop/Backdrop';
import NavigationItems from '../NavigationItems/NavigationItems';
import classes from './SideMenu.module.css';
import Logo from '../../Logo/Logo';

const sideMenu = (props) => {
    
    let attachedClass = [classes.SideMenu, classes.Close];

    if (props.open) {
        attachedClass = [classes.SideMenu, classes.Open];
    }

    return (
        <Auxillary>
            <Backdrop show={props.open} clicked={props.closed} />
            <section className={attachedClass.join(" ")} onClick={props.closed}>
                <Logo sideMenu />
                <NavigationItems />
                <div className={classes.CrossIcon}>
                    &#10005;
                </div>
            </section>
        </Auxillary>
    );
};

export default sideMenu;