import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Fume extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/portfolio", title: "Portfolio" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./dust-ventilation-equipment.jpg").default, alt: "Dust Ventilation Equipment" }]);
    }

    render () {
        const images = require.context('../../../assets/images', true);

        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>Check out this fume extraction project in Knowsley, serving ovens. A fume extractor system generally uses an activated carbon filter and a fan to remove the smoke, and noxious fumes from the active environment. Fumes (as with dust particles) are, hazardous to human health if they are inhaled. Continuous exposure to these fumes can and does cause serious respiratory diseases to your employees. In the short-term, it may seem less expensive to skip on costly preventative measures, but having the right system in place guarantees better employee health – which means less business expense in litigation, less downtime, better productivity and compliance. Above all else, it's simply the right thing to do!</p>
                <p>LEV Designs always recommends that your fume extractor system captures fumes at their source. This makes certain there is no chance of these gases remaining in your place of work. Clear guidance exists and is in place to provide guaranteed solutions to help to reduce the fumes that are generated to within the recommended and acceptable levels. LEV Designs will work with you to achieve these standards – peace of mind for a job well done!</p>
                <p>All LEV Designs' systems are designed to specifically meet your particular production requirements. Bespoke solutions tend to beat off-the-shelf products, which are not always suitable in hitting your target requirements.</p>
                <p>Before LEV Designs embarks on installing a LEV system, we ascertain the extent of the problem. We can undertake air monitoring of the work area, asking your engineers questions, we would build up a data sheet of detail to understand your production cycles, production processes, their needs and we plan a tailored solution to fit exactly what you require. Ask us for more on this. It could be the best small investment your company spends! It will provide you with a total and clear picture of the extent of the fume problem and will possibly save you thousands of pounds by not over-specifying an LEV system bigger than you actually need. That means a potential reduction in installation (capital) costs as well as maintenance and staffing costs (revenue).</p>
                <p>With all the LEV systems that LEV Designs installs, we always ensure your fume extraction system is placed as closely as possible to the activity or process that generates the fumes.</p>
                <p><img src={images("./fume-extraction.jpg").default} alt="Fume Extraction" className="img-fluid"/></p>
                <p><img src={images("./fume-extraction-ducting.jpg").default} alt="Fume Extraction Ducting" className="img-fluid"/></p>
                <p><img src={images("./fume-extractor-system.jpg").default} alt="Fume Extractor System" className="img-fluid"/></p>
                <p><img src={images("./fume-extraction-serving-ovens.jpg").default} alt="Fume Extractor Serving Ovens" className="img-fluid"/></p>
            </Auxillary>
        )
    }
    
};

Fume.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Fume;