import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Video extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/portfolio", title: "Portfolio" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./dust-extraction-solution.jpg").default, alt: "Dust Extraction Solution" }]);
    }

    render () {
        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>Shiny, new, clean and efficient. All LEV installations are built to deliver performance, reliability and robustness. Improve your throughput, deliver ahead of schedule and be proud in your outputs. You now have a workforce which is happier, healthier and more productive!</p>
                <p className="fluid-width-video-wrapper"><iframe title="lev-video" src="https://www.youtube.com/embed/CvjtA0Q5vVU" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe></p>
            </Auxillary>
        )
    }
    
};

Video.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Video;