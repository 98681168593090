import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Installation extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/portfolio", title: "Portfolio" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./fume-control-system.jpg").default, alt: "Fume Control System" }]);
    }

    render () {
        const images = require.context('../../../assets/images', true);

        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>LEV &amp; Air Movement Designs Ltd. has over 41 years' experience in design of Dust &amp; Fume Control Systems, HVAC systems and LEV Testing.</p>
                <p>When LEV &amp; Air Movement Designs were awarded the contract to undertake the LEV Examination and Test of all the production areas at a manufacturing facility in Sheffield, the results were amazing!</p>
                <p>The survey undertaken covered many areas and processes, from Laboratory and Welding bays, to the Shotblast area and Finishing Department.</p>
                <p>With LEV Designs' experience in the planning, construction and management of Dust and Fume extraction systems, LEV &amp; Air Movement Design were able to examine each LEV system and make recommendations for improvement to control measures.</p>
                <p>In one particular area of production, whilst the installed LEV plant met HSG 258 Guidelines with capture velocity criteria, whilst observing the operator during his rework procedure, concerns were raised with the LEV exhaust hood design.</p>
                <p>The enclosure hood design was considered too small for the product being worked on with the operator polishing outside the hood face, resulting in the dust particulate being generated by the process, not being contained and controlled fully.</p>
                <p>Also observed, the worktop height was low, resulting in the operator locating additional support frame on the the workbench to raise the product to a suitable working height.</p>
                <p>LEV &amp; Air Movement Designs discussed these concerns with the Health &amp; Safety Manager and, in December 2018, with increased production requirements, additional rework station was required. LEV &amp; Air Movement required were awarded the contract to design, manufacture &amp; supply the new LEV system.</p>
                <h2>Existing Workbench</h2>
                <p><img src={images("./lev-examination.jpg").default} alt="Lev Examination" className="img-fluid"/></p>
                <h2>New Bespoke Designed Work Bench</h2>
                <p>LEV designed exhaust workbench to HSG 258 Guidelines, complete with inter-connected duct work and mechanical clean type filter unit complete with integral fan set.</p>
                <p><strong>LEV tested after installation: Passed and meets design criteria.</strong></p>
                <p><img src={images("./exhaust-workbench.jpg").default} alt="Exhaust Workbench" className="img-fluid"/></p>
                <p><img src={images("./smoke-bench.jpg").default} alt="Smoke Workbench" className="img-fluid"/></p>
            </Auxillary>
        )
    }
    
};

Installation.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Installation;