import React from 'react';

import classes from './Logo.module.css';
import theLogo from '../../../assets/images/logo.png';
import Image from '../../UI/Image/Image';
import { NavLink } from 'react-router-dom';

const logo = (props) => (
    <div className={!props.sideMenu ? classes.Logo : classes.SideLogo}>
        <NavLink to="/" title="Home">
            <Image src={theLogo} alt="LEV Logo" useLazyLoad={false} />
        </NavLink>
        { !props.sideMenu ? <p>The Dust, Fume And Ventilation Experts</p> : null }
    </div>
);

export default logo;