import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Hood extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/portfolio", title: "Portfolio" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./lev-hood-design.jpg").default, alt: "LEV Hood Design" }]);
    }

    render () {
        const images = require.context('../../../assets/images', true);

        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>New design hood &amp; ducting for high speed mixer</p>
                <p><img src={images("./high-speed-mixer.jpg").default} alt="New Hood Design" className="img-fluid"/></p>
                <p>Existing hood</p>
                <p><img src={images("./hood-and-ducting.jpg").default} alt="Old Hood Design" className="img-fluid"/></p>
            </Auxillary>
        )
    }
    
};

Hood.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Hood;