import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Inspection extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/portfolio", title: "Portfolio" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./fume-extraction-ductwork.jpg").default, alt: "Fume Extraction Ductwork" }]);
    }

    render () {
        const images = require.context('../../../assets/images', true);

        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>Existing Ducting Layout</p>
                <p><img src={images("./lev-inspection.jpg").default} alt="LEV Inspection" className="img-fluid"/></p>
                <p>New Fume Extraction Layout</p>
                <p><img src={images("./fume-extractor-system.jpg").default} alt="Fume Extractor System" className="img-fluid"/></p>
            </Auxillary>
        )
    }
    
};

Inspection.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Inspection;