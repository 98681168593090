import React, { Component } from 'react';

import classes from './HeroImage.module.css';
import Image from '../UI/Image/Image';
import LinkButton from '../UI/LinkButton/LinkButton';

class HeroImage extends Component {
    state = {
        imageShown: 1,
        interval: null
    }

    // error when moving from home page to another page
    // componentDidMount () {
    //     if (this.props.images.length > 1)
    //     {
    //         this.setState({interval: setInterval(() => {
    //             var currentImageShown = this.state.imageShown;

    //             if (currentImageShown === 3) {
    //                 clearInterval(this.state.interval);
    //             }
    //             else {
    //                 this.setState((prevState) => { 
    //                     return { 
    //                         imageShown: prevState.imageShown + 1 
    //                     }});
    //                 this.showImages("heroImage" + this.state.imageShown);
    //             }
    //           }, 5000)});
    //     }
    // }

    showImages (whichClass) {
        this.props.images.forEach(img => {
            document.getElementsByClassName("heroImage" + img.key)[0].classList.add("d-none");
        });
        document.getElementsByClassName(whichClass)[0].classList.remove("d-none");
    }

    render () {
        const imageList = this.props.images.map(img => (
            <Image key={img.key} src={img.src} alt={img.alt} classList={["heroImage" + img.key, this.props.images.length > 1 && img.key > 1 ? "d-none" : null]} />
        ));

        const selectorClicks = this.props.images.map(img => (
            <span key={img.key} onClick={() => this.showImages("heroImage" + img.key)}></span>
        ));

        const selectors = (this.props.images.length > 1 ?
                                <div className={classes.Selectors}>
                                {selectorClicks}
                            </div> : null);

        const sellingPoints = this.props.heroSellingPoints.map(usp => (
            <li key={usp.key}>&#10003; {usp.text}</li>
        ));

        return (
            <section className={classes.HeroWrapper + " " + (this.props.images.length > 1 ? "" : classes.HeroImageStill)}>
                {imageList}
                <div className={classes.Overlay}>
                    <div>
                        <p className={classes.Bigger}>{this.props.useLEVHeader ? "Expertly Managed LEV Testing" : "Dust, Fume & Ventilation System Experts"}</p>
                        <ul className={classes.SellingPoints}>
                            {sellingPoints}
                        </ul>
                        {this.props.showFindOutMoreButton ? <LinkButton title="More About Us" classList="btn btn-lg btn-warning-outline" link="/about">FIND OUT MORE</LinkButton> : null}
                        {this.props.showContactUsButton ? <LinkButton title="Contact Us" classList="btn btn-lg btn-warning" link="/contact">CONTACT US</LinkButton> : null}
                    </div>
                </div>
                {selectors}
            </section>
        )
    }
};

export default HeroImage;