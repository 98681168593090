import React, { Component } from 'react';
import Head from "../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../hoc/Auxillary/Auxillary';

class Testimonials extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" }, { key: 2, link: "/testimonials", title: "Testimonials" }]);
        const images = require.context('../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./dust-reducing-bench.jpg").default, alt: "Dust Reducing Bench" }]);
    }

    render () {

        const reviewList = [
            { key: 1, feedback: "Joe at LEV Designs has supported our company in upgrading our extraction systems, very friendly, efficient service always on the other end of the phone for continued advice after doing a excellent installation of our system, LEV Designs have been so helpful to us in the area.", reviewBy: "Olivia Birkbeck, Robert Birkbeck & Son Limited" },
            { key: 2, feedback: "I have found Joe Cassidy and LEV Designs to be professional, friendly and down to earth offering a cost-effective service. At the investigation and proposal stage the attention to detail was very high resulting in accurate proposals and a practical scope of work. Joe demonstrated a thorough understanding and appreciation of my problems and made me feel that the advice offered was based on experience and not sales value. The standard of design and resulting workmanship of the LEV solutions proposed and implemented has been to a high quality. I am more than happy with the service delivered by LEV Designs and would use their services again on large or small projects and would recommend them for their skill set and attitude.", reviewBy: "Beatson Clark" },
            { key: 3, feedback: "We have used LEV designs for our annual LEV testing and recently installed a new dust extraction system in our facility with the expert support from LEV Designs. They assisted in recommending and sourcing the best available solution for our needs, designed the ideal layout and were available throughout the project to support on the installation through to completion. We would 100% recommend LEV and I look forward to working with them again in the future", reviewBy: "Peter Harrop, Benchmaster Limited" },
            { key: 4, feedback: "Hubron International have used the services of LEV Designs for a number of years on testing and installation, always totally satisfied with Joe and his team.", reviewBy: "Darren Johnston, Hubron International Ltd" },
            { key: 5, feedback: "I have used the services that Joe supplies over 25 years as and when required in various projects and always valued his input due to the amount of knowledge and experience he has!!", reviewBy: "Steve O'Brien, Volac International Ltd" }
        ];

        let reviewSection = reviewList.map(m => (
            <blockquote key={m.key}>
                <p>"{m.feedback}"</p>
                <p className="blockquote-footer"><cite title={m.reviewBy}>{m.reviewBy}</cite></p>
            </blockquote>
        ));

        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>Testimonials</h1>
                <p>Our team works hard to achieve suitable results for customers who require bespoke LEV ventilation systems and testing services. This is easy to see in our work and the feedback we receive from our clients:</p>
                {reviewSection}
            </Auxillary>
        )
    }
    
};

Testimonials.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Testimonials;