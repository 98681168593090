import React from 'react';

import classes from './NavigationItem.module.css';
import { NavLink } from 'react-router-dom';

const navigationItem = (props) => {

    let classesArray = [classes.NavigationItem];

    if (props.telephoneLink)
        classesArray.push(classes.PhoneNumber);

    return (
        <li className={classesArray.join(' ').trim()}>
            {props.telephoneLink == null ?
                <NavLink to={props.link} title={props.title}>{props.children}</NavLink> : 
                <a href={props.link} className="telno" title={props.title}>{props.children}</a>    
            }
        </li>
    );
};

export default navigationItem;