import React from 'react';

import Image from '../../UI/Image/Image';
import LinkButton from '../LinkButton/LinkButton';

const card = (props) => {
    return (
        <div className="card mb-4 mt-4">
            <Image src={props.imageSrc} alt={props.imageAlt} classList={["card-img-top"]}/>
            <div className="card-body">
                <h4>{props.title}</h4>
                <p>{props.children}</p>
                <LinkButton link={props.link} title={props.title} classList="btn btn-warning">View more</LinkButton>
            </div>
        </div>
    );
};

export default card;