import React, { Component } from 'react';
import Head from "../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../hoc/Auxillary/Auxillary';

class Home extends Component {

    componentDidMount() {
        const images = require.context('../../assets/images', true);
        this.props.setLocation([{ key: 1, link: "/", title: "Home" }]);
        this.props.setMainImage([{src: images("./dust-extraction-system.jpg").default, alt: "Dust Extraction System", key: 1},
                                {src: images("./ventilation-system.jpg").default, alt: "Ventilation System", key: 2},
                                {src: images("./lev-extraction-system.jpg").default, alt: "LEV Extraction System", key: 3}]);
    }

    generateH1Header() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let keywords = params.get('keyword');
        let headerText = "LEV Design Specialists";

        if (keywords) {
            keywords = keywords.replace("lev", "LEV").replace("hsg", "HSG").replace("coshh", "COSHH").replace("bohs", "BOHS").replace("hse", "HSE").trim();
            if (keywords.length > 2) {
                headerText = "";

                let lastChar = " ";

                //loop over characters in keywords and capitalise the first letter
                for (let i = 0; i < keywords.length; i++) {
                    headerText += lastChar === " " ? keywords.charAt(i).toUpperCase() : keywords.charAt(i);
                    lastChar = keywords.charAt(i);
                }
            }
        }

        return headerText;
    }

    render () {
        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.generateH1Header()}</h1>
                <p>Having worked for the top companies, we now have 40 years combined experience in designing dust, fume and general ventilation systems. We only offer you an independent recommendation (the correct solution). LEV Designs is not tied to any particular supplier. We offer you the best corrective designs that match your needs and work best with your current plant and machinery. After an adequate solution is in place, our engineers can provide LEV testing examinations every 14 months to protect your employees. Basically, you get the best advice to solve your problem.</p>
                <p>We run a lean operation. You get more for every pound to spend in assurances, quality of services, technical support and operational benefit</p>
                <h2>Unique Benefits in Brief</h2>
                <ul>
                    <li>Correct solution/corrective designs only</li>
                    <li>40 years combined experience</li>
                    <li>Lean operation</li>
                    <li>Independent survey for most cost-effective solution (quality/investment/performance)</li>
                    <li>Technical sales means quicker lead-time</li>
                </ul>
                <p>Most companies project / engineering departments have been reduced to a minimum therefore do not have the resources or time to evaluate projects efficiently. We are independent from all major manufacturers and therefore can offer unbiased recommendation and solutions for your specific requirements.</p>
            </Auxillary>
        )
    }
    
};

Home.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Home;