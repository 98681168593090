import React from 'react';

import classes from './Breadcrumb.module.css';
import LocationImage from '../../assets/images/map-marker-alt-solid.svg';
import Image from '../UI/Image/Image';
import { NavLink } from 'react-router-dom';

const breadcrumb = (props) => {

    var linkList = props.breadcrumbList.map(m => (
        <li key={m.key}><NavLink to={m.link} title={m.title}>{m.title}</NavLink></li>
    ));

    return (
        <section className={classes.Breadcrumb}>
            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ul className={classes.BreadcrumbList}>
                                <li><Image src={LocationImage} alt="Location Icon" /></li>
                                {linkList}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default breadcrumb;