import React from 'react';

import Image from '../../UI/Image/Image';
import classes from './Gallery.module.css';

const gallery = () => {
    const images = require.context('../../../assets/images', true);

    return (
        <section className={classes.Gallery}>
            <div className="container">
                <div className="row">
                    <div className="offset-1 col-10 offset-sm-0 col-sm-12">
                        <h3>Our <strong>Bespoke &amp; Compliant</strong> Local Exhaust Ventilation Systems</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="offset-1 col-10 offset-sm-0 col-sm-12 col-md-7">
                        <div className="row">
                            <div className="col-6 col-sm-4">
                                <Image src={images("./lev-filtration-plant.jpg").default} alt="LEV Filtration Plant" classList={["img-fluid", "mt-4"]} />
                            </div>
                            <div className="col-6 col-sm-4">
                                <Image src={images("./votator-extraction-system.jpg").default} alt="Votator Extraction system" classList={["img-fluid", "mt-4"]} />
                            </div>
                            <div className="col-6 col-sm-4">
                                <Image src={images("./galvanised-manufactured-clip-ducting.jpg").default} alt="Galvanised Manufactured Clip Ducting" classList={["img-fluid", "mt-4"]} />
                            </div>
                            <div className="col-6 col-sm-4">
                                <Image src={images("./ibc-bag-filling-station.jpg").default} alt="IBC Bag Filling Station" classList={["img-fluid", "mt-4"]} />
                            </div>
                            <div className="col-6 col-sm-4">
                                <Image src={images("./dust-extraction-system-design.jpg").default} alt="Dust Extraction System Design" classList={["img-fluid", "mt-4"]} />
                            </div>
                            <div className="col-6 col-sm-4">
                                <Image src={images("./linishing-bench-system.jpg").default} alt="Linishing Bench System" classList={["img-fluid", "mt-4"]} />
                            </div>
                        </div>
                    </div>
                    <div className="offset-1 col-10 offset-sm-2 col-sm-8 offset-md-0 col-md-5">
                        <Image src={images("./lev-smoke-test.jpg").default} alt="LEV Smoke Test" classList={["img-fluid", "mt-4 mt-md-0"]} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default gallery;