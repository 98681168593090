import React from 'react';

import Auxillary from '../../../hoc/Auxillary/Auxillary';
import { NavLink } from 'react-router-dom';

const linkButton = (props) => {
    const createLink = (props.clickEvent != null || props.link.indexOf("tel:") !== -1 || props.link.indexOf("emailto:") !== -1 ? 
                    <a href={props.link} title={props.title} className={props.classList} onClick={props.clickEvent == null ? null : props.clickEvent}>{props.children}</a> : 
                    <NavLink to={props.link} title={props.title} className={props.classList}>{props.children}</NavLink>);

    return (
        <Auxillary>
            {createLink}
        </Auxillary>
    );
}
    
export default linkButton;