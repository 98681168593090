import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Pollution extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/blog", title: "Blog" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./reduce-work-air-pollution.jpg").default, alt: "Reduce Air Pollution At Work" }]);
    }

    render () {
        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>With lockdowns reducing human activity to an all-time low across the nation, <a href="https://www.instantoffices.com/blog/instant-offices-news/air-pollution-in-cities-lockdown/" target="_blank" rel="nofollow noreferrer">global workspace specialist Instant Offices</a> have collected data and ranked the top 13 UK cities with the <strong>most significant drop and rise</strong> in air pollution just <strong>two weeks</strong> into lockdown.</p>
                <p>With some of the UK’s busiest cities coming to a halt, lockdown measures across the UK have seen the most dangerous air quality indicator PM2.5 levels plunge in 13 of the UKs largest cities, with <strong>London showing the biggest drop by 34.29%</strong>, followed by Leicester (<strong>20.88%</strong>) and Edinburgh (<strong>16.89%</strong>).</p>
                <p>While most cities saw dangerous particulate matter decline as human activity slowed down across the UK, Liverpool stands out with a huge <strong>19.15%</strong> increase, followed by Birmingham with an increase of <strong>9%</strong>.</p>
                <p>According to Centre for Cities, major contributors to air pollution across UK cities are transport and burning fuels, with half of PM2.5 toxins coming from coal fires and wood burning stoves.</p>
                <p><strong>Edward Harbison, Corporate Marketing Manager at Instant Offices commented</strong>, "The significant reduction of PM2.5 across major cities in only two weeks of lockdown, is evidence of the staggering impact we have on the world around us. With reduced human activity across the board, our analysis showed a significant improvement in some of the worlds most polluted cities. Considering the impact that PM2.5 has on air quality and our overall health, this is especially noteworthy."</p>
                <p><strong>Methodology</strong></p>
                <p>Comparing average PM2.5 levels from when lockdown started in each city to the same dates in 2019, Instant Offices calculated and ranked the areas with the biggest increase and decrease in PM2.5 levels just two weeks after lockdown started (March 23rd – April 6th).</p>
                <p>All data has been sourced from The World Air Quality Index. The organisation works with Environmental Protection Agencies worldwide, sourcing data from over 12, 000 air quality monitoring stations in 1000 major cities across more than 100 countries, providing the most comprehensive, consistent and accurate data on real-time global air quality.</p>
                <p>For all the information on the analysis that Instant Offices collected around the top cities with the largest improvements in air quality after two weeks of lockdown, you can find this here:</p>
                <p><a href="https://www.instantoffices.com/blog/instant-offices-news/air-pollution-in-cities-lockdown/" target="_blank" rel="nofollow noreferrer">https://www.instantoffices.com/blog/instant-offices-news/air-pollution-in-cities-lockdown/</a></p>
            </Auxillary>
        )
    }
    
};

Pollution.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Pollution;