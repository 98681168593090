import React, { Component } from 'react';
import Head from "../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../hoc/Auxillary/Auxillary';
import Card from '../UI/Card/Card';

class Blog extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" }, { key: 2, link: "/blog", title: "Blog" }]);
        const images = require.context('../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./fume-extraction-solution.jpg").default, alt: "Fume Extraction Solution" }]);
    }

    render() {
        let blogOutput = this.props.blogList.map(m => (
            <div key={m.key} className="col-10 offset-1 col-md-6 offset-md-0">
                <Card imageSrc={m.imageSrc}
                        imageAlt={m.imageAlt}
                        title={m.title}
                        link={m.link}>{m.preview}</Card>
            </div>
        ));

        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>Blog</h1>
                <div className="row">
                    {blogOutput}
                </div>
            </Auxillary>
        )
    }
};

Blog.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Blog;