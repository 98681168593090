import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';
import PhoneImage from '../../../../assets/images/phone-alt-solid.svg';
import Image from '../../../UI/Image/Image';

const navigationItems = (props) => {
    let classList = [classes.NavigationItems];

    if (props.desktopOnly)
        classList.push(classes.DesktopOnly);

    var now = new Date();
    var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    var day = days[ now.getDay() ];
    var h = now.getHours();
    var m = now.getMinutes();

    let badge = day !== "Sunday" && day !== "Saturday" && (h > 8 || (h === 8 && m >= 30)) && (h < 17 || (h === 17 && m <= 30)) ? <strong className="badge badge-warning">Ready</strong> : "";

    return (        
        <nav className={classList.join(" ")}>
            <ul>
                <NavigationItem link="/about" title="About Us"><span>&#10097;</span>About Us</NavigationItem>
                <NavigationItem link="/services" title="Services"><span>&#10097;</span>Services</NavigationItem>
                <NavigationItem link="/portfolio" title="Portfolio"><span>&#10097;</span>Portfolio</NavigationItem>
                <NavigationItem link="/testimonials" title="Testimonials"><span>&#10097;</span>Testimonials</NavigationItem>
                <NavigationItem link="/contact" title="Contact Us"><span>&#10097;</span>Contact Us</NavigationItem>
                <NavigationItem link="tel:01617631937" title="Call Us" telephoneLink={true}>
                    <Image src={PhoneImage} alt="Phone Icon" useLazyLoad={false} /> 0161 763 1937 {badge}
                </NavigationItem>
            </ul>
        </nav>
    );
};

export default navigationItems;