import React from 'react';

import classes from './Header.module.css';
import Logo from '../Header/Logo/Logo';
import NavigationItems from './Navigation/NavigationItems/NavigationItems';
import MenuToggle from './Navigation/SideMenu/MenuToggle/MenuToggle';
import SideMenu from './Navigation/SideMenu/SideMenu';
import Auxillary from '../../hoc/Auxillary/Auxillary';

const header = (props) => (
    <Auxillary>
        <header className={classes.Header}>
            <Logo />
            <NavigationItems desktopOnly={true} />
            <MenuToggle clicked={props.menuToggleClicked} />
        </header>
        <SideMenu open={props.openSideMenu} closed={props.sideMenuClosed} />
    </Auxillary>
);

export default header;