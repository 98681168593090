import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Equipment extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/blog", title: "Blog" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./dust-ventilation-equipment.jpg").default, alt: "Dust Ventilation Equipment" }]);
    }

    render () {
        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>Dust extraction systems actually lower housekeeping costs through keeping the workspace free of dust.</p>
                <p>With an effective strategy to minimise dust levels, companies can avoid costly equipment breakdowns. That means greater productivity and a better return on investment from plant, machinery and labour. Dust extraction also will protect your workforce – ensuring health and attrition due to illness is much lower. There's also the additional benefit that it reduces your energy use, improves processes and prevents catastrophic events such as fires and acute equipment malfunctions just by installing an effective dust control system.</p>
                <p>LEV Design makes sure the solution you have is the one you need. It fits your operational model, matches your throughput and integrates fully with protocols and systems your already work to. It's made to fit you and is totally designed around your process and your application. From off-the-shelf designs and templates to bespoke one-off solutions, every design is fit for purpose and gives you all the benefits of efficiency, increased assurance, higher throughput and less downtime. From fully installed systems to locally-based on-site solutions in key hazard zones, we can cater for all of your needs.</p>
                <p>LEV Designs has experienced and qualified engineers, dedicated and technically experienced in all related disciplines to ensure your needs are fully met. Call us today for a quote. Visits to your premises can be arranged quickly and easily.</p>
            </Auxillary>
        )
    }
    
};

Equipment.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Equipment;