import React from 'react';

import './Footer.css';
import Image from '../UI/Image/Image.js';
import { NavLink } from 'react-router-dom';
import ContactForm from '../ContactForm/ContactForm';

const footer = () => {
    const images = require.context('../../assets/images', true);
    return (
        <footer>
            <section className="container">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <p>Contact Details</p>
                        <ul className="grey-bgd">
                            <li><strong>Landline:</strong> <a href="tel:01617631937" className="telno" title="Call Landline">0161 763 1937</a></li>
                            <li className="mobno-sect"><strong>Mobile:</strong> <a href="tel:07490880881" className="mobno" title="Call Mobile">07490 880 881</a></li>
                            <li><strong>Email:</strong> <a href="emailto:info@levdesigns.co.uk" className="emailaddress" title="Email Us">info@levdesigns.co.uk</a></li>
                            <li><strong>Address:</strong> 69F Church Street, Ainsworth, Bolton, BL2 5RD</li>
                            <li><strong>Open:</strong> Monday to Friday (8.30am - 5.30pm)</li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-4 col-lg-3">
                        <p>Quick Links</p>
                        <ul className="grey-bgd">
                            <li>▸ <NavLink to="/" title="Home">Home</NavLink></li>
                            <li>▸ <NavLink to="/about" title="About Us">About Us</NavLink></li>
                            <li>▸ <NavLink to="/services" title="Services">Services</NavLink></li>
                            <li>▸ <NavLink to="/portfolio" title="Portfolio">Portfolio</NavLink></li>
                            <li>▸ <NavLink to="/contact" title="Contact Us">Contact Us</NavLink></li>
                            <li>▸ <NavLink to="/privacy" title="Privacy Policy">Privacy Policy</NavLink></li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                        <p>Speak To Us</p>
                        <div className="row">
                            <div className="col-12 green-bgd">
                                <ContactForm fullWidth={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-2 col-lg-1">
                        <p>Socials</p>
                        <ul className="list-style-inline">
                            <li><a href="https://www.facebook.com/LEVTesting" title="Facebook" target="_blank" rel="noreferrer"><Image src={images("./facebook-square-brands.svg").default} alt="Facebook" /></a></li>
                            <li><a href="https://twitter.com/LEV_Designs" title="Twitter" target="_blank" rel="noreferrer"><Image src={images("./twitter-square-brands.svg").default} alt="Twitter" /></a></li>
                            <li><a href="https://www.linkedin.com/company/lev-designs" title="LinkedIn" target="_blank" rel="noreferrer"><Image src={images("./linkedin-brands.svg").default} alt="LinkedIn" /></a></li>
                            <li><a href="https://www.instagram.com/levdesignsuk/" title="Instagram" target="_blank" rel="noreferrer"><Image src={images("./instagram-square-brands.svg").default} alt="Instagram" /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <p><strong>© {new Date().getFullYear()} - LEV &amp; Air Movement Designs Ltd trading as LEV Designs</strong></p>
                        <p>All rights reserved. Registered in England - 10149853.</p>
                    </div>
                </div>
            </section>
        </footer>
    );
};

export default footer;