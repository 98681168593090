import React, { Component } from 'react';
import Head from "../Head/Head";
import ContactForm from './../ContactForm/ContactForm.js';
import Image from '../UI/Image/Image';

import Auxillary from '../../hoc/Auxillary/Auxillary';

class Contact extends Component {
    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" }, { key: 2, link: "/contact", title: "Contact Us" }]);
        const images = require.context('../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./lev-design-system.jpg").default, alt: "LEV Design System" }]);
        this.props.hideContactUsButton();
    }

    render () {
        const images = require.context('../../assets/images', true);
        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>Contact LEV Designs</h1>
                <div className="row mt-4 mb-4">
                    <div className="col-12 col-xl-4">
                        <h2 className="green-block">Call Us</h2>
                        <p><Image src={images("./black-building.gif").default} alt="Black Phone" /> <a href="tel:01617631937" className="ml-sm-2 logo-green telno">0161 763 1937</a></p>
                        <p><div className="mobno-sect"><Image src={images("./black-phone.gif").default} alt="Black Building" /> <a href="tel:07490880881" className="ml-sm-2 logo-green mobno">07490 880 881</a></div><small className="d-block">(Mon to Fri 8.30am - 5.30pm)</small></p>
                    </div>
                    <div className="col-12 col-xl-8">
                        <h2 className="green-block">Email / Visit Us</h2>
                        <p><Image src={images("./black-email.gif").default} alt="Black Email" /> <a href="mailto:info@levdesigns.co.uk" title="Email Us" className="ml-sm-2 break-word logo-green emailaddress">info@levdesigns.co.uk</a></p>
                        <address>
                            <p><Image src={images("./black-pin.gif").default} alt="Black Pin" /> <span className="ml-sm-2">69F Church Street, Ainsworth, Bolton, BL2 5RD</span></p>
                        </address>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 p-4 box-shadow">
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="mt-0">We'll <span className="font-weight-bold">call you</span> back</h3>
                                    </div>
                                </div>
                                <ContactForm fullWidth={false} />
                            </div>
                        </div>
                    </div>
                </div>
            </Auxillary>
        )
    }
};

export default Contact;