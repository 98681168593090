import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Manufacturing extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/blog", title: "Blog" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./lev-design-for-manufacturing.jpg").default, alt: "LEV Design For Manufacturing" }]);
    }

    render () {
        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p><strong>Latest Manufacturing Industry Figures Make for a Stern Warning, Says Midland Pallet Trucks</strong></p>
                <p>UK firm Midland Pallet Trucks – which provides the manufacturing and warehousing industries with a wide range of materials handling equipment, including  high lift pallet trucks and weighing scale trucks has warned that the latest manufacturing industry figures could show the road ahead for the foreseeable future.</p>
                <p>While the manufacturing industry is still growing, Midland Pallet Trucks is urging caution as that growth slows to a 25-month low. With the Purchasing Manager’s Indices (PMI) showing a 54.6 percent growth rate, many might wonder where the supposed problem lies. But the West Midlands firm is keen to ensure those in the industry don’t miss the warning signs – which are plenty.</p>
                <p>Amidst the glowing growth figures, it has gone unnoticed by many that the sector is actually in the midst of its slowest growth period for two years. At the same time, business confidence in post-Brexit trading conditions and broader concerns about the sagging global recovery are having a real impact on the growth, export and workforce figures of the manufacturing industry. Midland Pallet Trucks has pointed to the significant slowing of output and new export orders as big areas for concern. Over the past two and a half years, these indicators of sustainable, healthy growth have grown month-on-month. But now the picture is very different. Thanks in part due to a weaker pound and government confusion over exactly what form Brexit will take, the slowdown in the manufacturing industry has also seen jobs growth grind to a standstill.</p>
                <p>And, while 47-percent of UK manufacturers still expect increased production over the next year, this number is at its lowest for two years. This slowdown in exports and growth has coincided with similar sluggish performance in Europe, with Germany, Spain and Italy experiencing the same issues.</p>
                <p>Phil Chesworth, Managing Director of Midland Pallet Trucks, said, “These latest figures make for some worrying reading for the manufacturing sector, and it’s easy to see how these same factors could affect other industries in similar ways. Some might see the slowdown as a temporary blip because, after all, growth has been strong for a couple of years now.</p>
                <p>"Other might say that Brexit uncertainty has finally bitten the manufacturing sector, which typically remains bullish in the face of obstacles. But since the sector has never faced anything like Brexit before, it could be that the lack of insight into exactly what is going to happen when we leave the EU is having very real, negative effects for the sector. Whatever your opinion on the source of the problem, the manufacturing sector is showing some serious causes for concern."</p>
                <p>To find out more, visit <a href="https://www.midlandpallettrucks.com" target="_blank" rel="nofollow noreferrer">https://www.midlandpallettrucks.com</a></p>
            </Auxillary>
        )
    }
    
};

Manufacturing.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Manufacturing;