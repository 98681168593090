import React, { Component } from 'react';

import Image from '../../UI/Image/Image';
import classes from './CaseStudies.module.css';
import TickImage from '../../../assets/images/check-solid.svg';

class CaseStudies extends Component {
    state = {
        image1: {src: "./minimise-dust-levels.jpg", alt: "Minimise Dust Levels"},
        image2: {src: "./extraction-machine.jpg", alt: "Extraction Machine"},
        image3: {src: "./lev-extraction.jpg", alt: "LEV Extraction"},
        image4: {src: "./microblade.jpg", alt: "Microblade System"}
    }

    handleImageClick = (whichImage) => {
        this.setState((prevSate) => { //setting state when rely on old state
            return {image1: {src: prevSate[`image${whichImage}`].src, alt: prevSate[`image${whichImage}`].alt},
                    ["image" + whichImage]: {src: prevSate.image1.src, alt: prevSate.image1.alt}};
        });
    }

    render () {
        const images = require.context('../../../assets/images', true);
        return (
            <section className={classes.CaseStudies}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="row">
                                <div className="col-12 col-sm-10 offset-sm-1 col-lg-12 offset-lg-0">
                                    <Image src={images(this.state.image1.src).default} alt={this.state.image1.alt} classList={["img-fluid"]} />
                                    <div className={classes.TextOverlay}>{this.state.image1.alt}</div>
                                </div>
                                <div className="col-6 col-sm-4 pt-3">
                                    <Image src={images(this.state.image2.src).default}  alt={this.state.image2.alt} classList={["img-fluid", "pointer", "hover-opacity"]} imageClicked={() => this.handleImageClick(2)} />
                                </div>
                                <div className="col-6 col-sm-4 pt-3">
                                    <Image src={images(this.state.image3.src).default} alt={this.state.image3.alt} classList={["img-fluid", "pointer", "hover-opacity"]} imageClicked={() => this.handleImageClick(3)}/>
                                </div>
                                <div className="d-none d-sm-block col-sm-4 pt-3">
                                    <Image src={images(this.state.image4.src).default} alt={this.state.image4.alt} classList={["img-fluid", "pointer", "hover-opacity"]} imageClicked={() => this.handleImageClick(4)}/>
                                </div>
                            </div>
                        </div>
                        <div className="offset-1 col-10 offset-md-0 col-md-12 mt-4 col-lg-6 mt-lg-0">
                            <h2>Highly rated LEV Design solutions</h2>
                            <p>Having worked for the top companies, we now have 40 years combined experience in designing dust, fume and general ventilation systems. We only offer you an independent recommendation (the correct solution). LEV Designs is not tied to any particular supplier. We offer you the best corrective designs that match your needs and work best with your current plant and machinery. Basically, you get the best advice to solve your problem for an affordable cost.</p>
                            <hr />
                            <ul>
                                <li><Image src={TickImage} alt="Tick" /> Dedicated independent engineered solutions</li>
                                <li className="d-lg-none d-xl-block"><Image src={TickImage} alt="Tick" /> Adept skill-base to deliver the best for you every time</li>
                                <li className="d-lg-none d-xl-block"><Image src={TickImage} alt="Tick" /> Enable a healthy environment for your workforce</li>
                                <li className="d-lg-none d-xl-block"><Image src={TickImage} alt="Tick" /> Series of solutions to make your business fit for purpose</li>
                                <li className="d-lg-none d-xl-block"><Image src={TickImage} alt="Tick" /> Avoid triggering or aggravating allergies from pollutants</li>
                                <li className="d-lg-none d-xl-block"><Image src={TickImage} alt="Tick" /> Sensible, experienced advice for great air quality</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
};

export default CaseStudies;