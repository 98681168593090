import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Warburtons extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/portfolio", title: "Portfolio" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./air-flow-control-system.jpg").default, alt: "Air Flow Control System" }]);
    }

    render () {
        const images = require.context('../../../assets/images', true);

        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>When you experience the huge benefits in an efficient and productive LEV system, you think of 2 things:</p>
                <ul>
                    <li>Why didn't we do it sooner?</li>
                    <li>How did we live without it?</li>
                </ul>
                <p>Now, everyone likes a crumpet, especially a Warburton's crumpet. So, when we installed a new system for their crumpet line at AB Mauri UK &amp; Ireland, we were absolutely delighted to hear key people in the production team, saying things internally like:</p>
                <ul>
                    <li>"... and there is a marked improvement."</li>
                    <li>"... and he said it was fantastic."</li>
                    <li>"... a million times better than what we were doing!"</li>
                </ul>
                <p>So much so, that Projects Manager, Steve Bannister, felt he had to write to us, to pass on this internal praise!</p>
                <p><i>"Hi Joe, Thought I would forward this as it is high praise on the LEV install on level 1 at Corby site. Thanks, great job and good final install!"</i></p>
                <p>We don't always blow our own crumpet, but we simply couldn't help it! Butter wouldn't melt! So, take a look below at some of the before/after shots and the detail. Call us to book your review, test or even to organise a quote.</p>
                <p>Just wait until you, too, enjoy comments like these wonderful ones from AB Mauri UK &amp; Ireland! <strong>Efficiencies, lead-time reduction, safer, healthier work environment, peace of mind... it's all within your reach!</strong></p>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <img src={images("./old-lev-system.jpg").default} alt="Old LEV System" className="img-fluid"/>
                    </div>
                    <div className="col-12 col-lg-6">
                        <img src={images("./extraction-bag.jpg").default} alt="Old Extraction Bag" className="img-fluid"/>
                    </div>
                </div>
                <p>This is the general area for the bag filling process.</p>
                <p>Bags are filled from the production area in the factory floor above.</p>
                <p>Food material is processed above and fed into the IBC big bags.</p>
                <p>Previously, there was a LEV system installed.</p>
                <p>It had a crude filter membrane fitted over the displaced air outlet spigot.</p>
                <p>When the process was in operation, the contaminated air and dust particulate escaped into the packing area, causing distress to the operative(s) working in the area.</p>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <img src={images("./clean-filter-fabric-unit.jpg").default} alt="Clean Filter Fabric Unit" className="img-fluid"/>
                    </div>
                    <div className="col-12 col-lg-6">
                        <img src={images("./flameless-vent-system.jpg").default} alt="Flameless Vent System" className="img-fluid"/>
                    </div>
                </div>
                <p>This shows the new LEV system installed consisting of a small mechanical clean fabric filter unit.</p>
                <p>Rear explosion relief panel fitted with Hoerbiger flameless vent.</p>
                <p>Non-return explosion valve fitted in the exhaust ducting.</p>
                <p>Air brake connection located adjacent to the displaced air discharge point.</p>
            </Auxillary>
        )
    }
    
};

Warburtons.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Warburtons;