import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Wood extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/blog", title: "Blog" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./wood-dust-extraction.jpg").default, alt: "Wood Dust Extraction" }]);
    }

    render () {
        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>Companies must protect their employees, contractors and site visitors from the dangers of wood dust. LEV Designs installs Dust Extraction Systems to safely remove wood dust.</p>
                <p>Wherever woodworking is taking place, adequate wood dust extraction systems should always be installed and fully operational. From the large extraction systems you see in saw-mills, to the wood dust air cleaners operating in school Design/Tech workshops, LEV Designs supplies the very latest designs and ranges in Dust Extraction Units to suit any scale. Many large areas where employees and customers come into contact with wood dust are also protected – even for what may seem to be smaller quantities than in industrial sites. Places where wood cutting occurs such as DIY stores alway carry out risk assessments to mitigate the dangers of dust being present in the air we breathe. The method statements which mitigate the risk always insist that adequate Dust Extraction Systems are on site and in place at the point where the dust is being generate to safely extract the dust and protect people on site.</p>
                <p>"Wood dust can cause serious health problems. It can cause asthma, which carpenters and joiners are four times more likely to get compared with other UK workers. The Control of Substances Hazardous to Health (COSHH) Regulations 2002 require that you protect workers from the hazards of wood dust. Hardwood dust can cause cancer, particularly of the nose. Settled dust contains the fine particles that are most likely to damage the lungs."<a href="http://www.HSE.gov.uk" target="_blank" title="HSE" rel="nofollow noreferrer">http://www.HSE.gov.uk</a></p>
                <p>To arrange a free LEV Designs site survey to understand whether your place of work requires a wood dust filtration unit or a complete dust extraction system.</p>
            </Auxillary>
        )
    }
    
};

Wood.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Wood;