import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Clean extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/blog", title: "Blog" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./ventilation-for-manufacturing.jpg").default, alt: "Ventilation For Manufacturing" }]);
    }

    render () {
        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>All set for Clean Air Day on Thursday? You are part of something that’s already huge, with tens of millions reached through traditional and digital media, and hundreds of events planned – including a Parliamentary gathering on air pollution today and an-all day city mayors’ conference tomorrow, at which we are speaking.</p>
                <p>As for the day itself... excitement doesn’t begin to describe the mood in our office and we can’t wait to share with you all the things that happen on the big day.</p>
                <p>In the meantime, the reason for this quick email is that we want to put in a plea that you capture as much as you can about all the great things you are making happen in your area/school/workplace.</p>
                <p>Global Action Plan, the charity I work for and the organisers of Clean Air Day, need as much information as possible about what’s happening on and around 21 June.</p>
                <p>This way we can more accurately measure the true impact of Clean Air Day, share more widely all the amazing events that took place across the country, and help inform the campaign over coming months and into next year.</p>
                <p>So please make a note-to-self to take a few minutes, when the dust settles on Friday morning, to fill in our online evaluation form. As a little incentive (as if you needed one!) you’ll be entered into a draw for one of three hampers, and we’ll email you a certificate acknowledging your part in the day.</p>
                <p>Thanks for being a supporter of Clean Air Day and our pollution-busting campaign. Together we’re making this a day to remember.</p>
                <p><strong>Dominic and the Clean Air Day team</strong></p>
            </Auxillary>
        )
    }
    
};

Clean.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Clean;