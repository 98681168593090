import React from 'react';

import Auxillary from '../../hoc/Auxillary/Auxillary';
import CallToAction from '../../components/Banners/CallToAction/CallToAction';
import CaseStudies from '../../components/Banners/CaseStudies/CaseStudies';
import Reviews from '../../components/Banners/Reviews/Reviews';
import Process from '../../components/Banners/Process/Process';
import Gallery from '../../components/Banners/Gallery/Gallery';

const banners = (props) => (
    <Auxillary>
        <Gallery />
        <CallToAction />
        <Process />
        <CaseStudies />
        <Reviews />
    </Auxillary>
);

export default banners;