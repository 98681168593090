import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Bakery extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/portfolio", title: "Portfolio" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./reverse-pulse-filter-units.jpg").default, alt: "Reverse Pulse Filter Units" }]);
    }

    render () {
        const images = require.context('../../../assets/images', true);

        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>A leading manufacturer, who supplies bakeries with formulated ingredients to meet client’s quality standards, thought they had a suitable LEV system installed which would control dust levels within the working area below the Workplace Exposure Limits (WEL) specified guidelines.</p>
                <p>Unfortunately the systems installed consisted of filter units, ducting and off the shelf positional exhaust hoods. Due to the nature of the weighing plus mixing process, this installation was deemed not satisfactory and did not meet WEL guidelines as it relied on the operators to locate the hoods to the dust generation source as required.</p>
                <p>LEV designs engineers’ carried out a site survey and concluded that the existing system had too many dust generated sources within the working area for one positional hood to work efficiently:</p>
                <ul>
                    <li>Mixer bowl area</li>
                    <li>Weigh table station</li>
                    <li>Sieving station</li>
                    <li>Material storage / bag unloading</li>
                </ul>
                <p><img src={images("./mixer-bowl-area.jpg").default} alt="Mixer Bowl Area" className="img-fluid"/></p>
                <p>After consultation with the client and evaluating the operators working procedures in the area, a new LEV system was designed and specified to the client before being installed with the assistance of a local engineering firm.</p>
                <p>The new and bespoke LEV plant installation design consists of:</p>
                <ul>
                    <li>Two off laminar flow booth workstations</li>
                    <li>Two off Reverse pulse filter units ATEX rated</li>
                    <li>Filter units fitted with Fike Flameless Quench</li>
                    <li>Interconnecting ducting between booth and Filter Units incorporating ATEX rated non-return valve</li>
                </ul>
                <p><img src={images("./filter-units.jpg").default} alt="Filter Units" className="img-fluid"/></p>
                <p><img src={images("./lev-filters.jpg").default} alt="LEV Filters" className="img-fluid"/></p>
            </Auxillary>
        )
    }
    
};

Bakery.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Bakery;