import React from 'react';

import classes from './CookieDisclaimer.module.css';
import LinkButton from '../UI/LinkButton/LinkButton';

import { NavLink } from 'react-router-dom';

const cookieDisclaimer = (props) => (
    props.showCookiesMessage ?
        <section className={classes.CookieDisclaimer}>
            <div className={classes.CookieContainer + " container"}>
                <div className="row">
                    <div className="col-12 col-md-9">
                        <p>We have placed cookies on your device to improve the website<span className="d-none d-lg-inline"> and enhance the users experience</span>. Please view our <NavLink to="/cookies" title="Cookie Policy" className={classes.CookieLink}>Cookie Policy</NavLink> for more info<span className="d-none d-sm-inline">rmation</span><span className="d-none d-lg-inline"> about the cookies we use</span>.</p>
                    </div>
                    <div className="col-12 col-md-3 mt-3 mt-md-0 text-lg-right">
                        <LinkButton title="Agree To Cookies" classList={classes.CookieBtn + " btn btn-lg btn-light"} clickEvent={props.acceptedCookiesClick}>Accept Cookies</LinkButton>
                    </div>
                </div>
                <span className={classes.CloseBtn} onClick={props.acceptedCookiesClick}>x</span>
            </div>
        </section>
    : ""
);

export default cookieDisclaimer;