import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class Postponed extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" },
                                { key: 2, link: "/blog", title: "Blog" },
                                { key: 3, link: this.props.link, title: this.props.title }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./air-flow-control-system.jpg").default, alt: "Air Flow Control System" }]);
    }

    render () {
        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.props.title}</h1>
                <p>In light of recent events with COVID-19, the Clean Air Day team have made the difficult decision to postpone Clean Air Day 2020 to 8th October.</p>
                <p>The success of our Clean Air Day campaign relies heavily on multiple organisations and communities coming together to demonstrate the difference we can all make to the air that we breathe. Whilst many activities can be undertaken digitally, the number one priority is the health and wellbeing of the UK public so postponing is the safest option.</p>
                <p>The team have selected 8th October as the new Clean Air Day for 2020 when they hope mass gatherings can resume. This will give them enough time to team up with schools, workplaces and local communities once everyone is back up and running.</p>
                <p>The Clean Air Day team are excited about the new opportunities the new date will bring, such as building on Car Free Day on 22 September and working in tandem with Walk to School Month in October. This time of year will also allow the team to extend the campaign to new areas such as domestic burning and indoor air quality as well as continuing to build on the core campaign theme around leaving the car at home.</p>
                <p>All messages will be shaped by the outcomes of the coronavirus pandemic and will look to build sensitively and constructively on how the Clean Air Day team have learned to work and live differently.</p>
                <p>The team has announced that they hope you understand their decision and that you will continue to support the campaign on 8th October for Clean Air Day 2020.</p>
            </Auxillary>
        )
    }
    
};

Postponed.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Postponed;