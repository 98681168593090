import React, { Component } from 'react';
import Head from "../../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../../hoc/Auxillary/Auxillary';

class LEV extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" }, { key: 2, link: "/services", title: "Services" }, { key: 3, link: "/services/lev-testing", title: "LEV Testing" }]);
        const images = require.context('../../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./lev-testing.jpg").default, alt: "LEV System Tests" }]);
        this.props.useLEVHeaderHandler();   
    }

    generateH1Header() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let keywords = params.get('keyword');
        let headerText = "LEV Testing";

        if (keywords) {
            keywords = keywords.replace("lev", "LEV").replace("hsg", "HSG").replace("coshh", "COSHH").replace("bohs", "BOHS").replace("hse", "HSE").trim();
            if (keywords.length > 2) {
                headerText = "";

                let lastChar = " ";

                //loop over characters in keywords and capitalise the first letter
                for (let i = 0; i < keywords.length; i++) {
                    headerText += lastChar === " " ? keywords.charAt(i).toUpperCase() : keywords.charAt(i);
                    lastChar = keywords.charAt(i);
                }
            }
        }

        return headerText;
    }

    render () {
        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>{this.generateH1Header()}</h1>
                <h2>LEV Systems</h2>
                <p>Local exhaust ventilation systems improve the quality of air by removing damaging substances, like dust particles and fumes, to help keep employees safe as they undergo their jobs.</p>
                <h2>What Is LEV Testing?</h2>
                <p>The thorough examination process tests your local exhaust ventilation system against the performance recorded in the commissioning report which is a legal requirement. It will include airflow and pressure measurements, checks on control effectiveness and, possibly, exposure measurement.</p>
                <p>If you don't have the design performance data, you will not know whether your LEV system is working correctly. We have extensive experience within the industry, can assess your systems performance and provide guidance on enhancing the air quality throughout the premises.</p>
                <p>If your system has already been commissioned but you have changed the process or layout since then, you will need to recommission it.</p>
                <p>We as experienced LEV engineers can help work out what you need to do.</p>
                <h2>LEV COSHH Test Frequency</h2>
                <p>LEV equipment should be examined and tested at least once every fourteen months or, in the case of some substances and processes, e.g. shot blasting units, grinding extracts, at shorter intervals.</p>
                <p>A suitable record of the examinations and tests carried out should be kept for at least five years, from the date on which they were made.</p>
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Process</th>
                            <th>Frequency</th>
                        </tr>
                        <tr>
                            <td>Processes in which blasting is carried out in or incidental to the cleaning of metal castings in connection with their manufacture.</td>
                            <td>1 month</td>
                        </tr>
                        <tr>
                            <td>Jet cloth manufacture</td>
                            <td>1 month</td>
                        </tr>
                        <tr>
                            <td>Processes, other than wet processes, in which metal articles (other than gold, platinum or iridium) are ground, abraded or polished using mechanical power, in any room for more than 12 hours per week</td>
                            <td>6 months</td>
                        </tr>
                        <tr>
                            <td>Processes giving off dust or fume in which non-ferrous metal castings are produced</td>
                            <td>6 months</td>
                        </tr>
                    </tbody>
                </table>
                <h2>LEV Testing Regulations</h2>
                <p>The Health and Safety at Work acts 1974 and regulation 9 of the Control of Substances Hazardous to Health state that Local Exhaust Ventilation (LEV) must be monitored and maintained to ensure it is in good working order including those that remove airborne dust and fumes.</p>
                <h2>Local Exhaust Ventilation Checklist</h2>
                <p>Here is a list of routine checks to make when inspecting an LEV system and we would encourage employees to notify their supervisors of any problems that they encounter with the system:</p>
                <ul>
                    <li>Does the indicator show the LEV is working properly?</li>
                    <li>Is it taking away all the harmful dust, mist, fumes and gas? Remember, some of these may be invisible.</li>
                    <li>Are you close enough to it so it can do its job properly?</li>
                    <li>Are there any signs it is not working properly, like smells or settled dust?</li>
                    <li>Are there any unusual noises or vibrations coming from it?</li>
                    <li>Has it been thoroughly tested, with a 'tested' label that is within date?</li>
                </ul>
                <h2>Thorough LEV Inspections</h2>
                <p>A comprehensive report will be produced for each piece of LEV equipment which includes the following in line with guidance from HSG258:</p>
                <ul>
                    <li>The name and address of the employer responsible for the LEV.</li>
                    <li>The date of the examination and test. The date of the last thorough examination and test.</li>
                    <li>The identification and location of the LEV, the process and the hazardous substance concerned.</li>
                    <li>The conditions at the time of the test and whether this was normal production or special conditions.</li>
                    <li>A simple diagram of the LEV layout and location with test points.</li>
                    <li>The condition of the LEV system including hood serial numbers and, where appropriate, photographs of relevant parts. Its intended operating performance for adequately controlling the hazardous substance and whether it is still achieving the same performance.</li>
                    <li>The methods used to make a judgement of the performance and what needs to be done to achieve that performance, e.g. visual, pressure managements, airflow.</li>
                    <li>Measurements, dust lamp, air sampling, tests to check the condition and effectiveness of the filter;</li>
                    <li>Comments on the way operators used the LEV.</li>
                    <li>Comments of system wear and tear and whether components need repair or replacement before the next annual test.</li>
                    <li>The name, job title and employer of the person carrying out the examination and test.</li>
                    <li>The signature of the person carrying out the examination and test.</li>
                    <li>The details of any minor adjustments or repairs carried out to make the LEV system effective.</li>
                </ul>
                <h2>Booking Your LEV Test</h2>
                <p>To book your LEV test, simply give our team a call to discuss your needs and we will arrange an appointment for an engineer to inspect your equipment. The process is efficient, professionally managed and highly reputable across the North West.</p>
            </Auxillary>
        )
    }
    
};

LEV.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default LEV;