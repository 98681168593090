import React from 'react';

import classes from './Reviews.module.css';
import ReviewItem from './ReviewItem/ReviewItem';

const reviews = () => {
    const images = require.context('../../../assets/images', true);
    const reviewList = [
        { key: 1, companyLogo: images("./beatson-clark.gif").default, clientName: "Beatson Clark", title: "Matthew Powell", message: "LEV Designs were quick to attend site and very helpful informing us of what was required to achieve compliance. They were also very flexible when asked for the design to be altered to include extra extraction points, and prompt with quote deliveries." },
        { key: 2, companyLogo: images("./hubron-logo.gif").default, clientName: "Hubron", title: "Darren Johnston", message: "Hubron International have used the services of LEV Designs for a number of years on testing and installation, always totally satisfied with Joe and his team, Darren Johnston – Hubron International Ltd" },
        { key: 3, companyLogo: images("./bench-master-logo.gif").default, clientName: "Bench Master", title: "Peter Harrop", message: "We have used LEV designs for our annual LEV testing and recently installed a new dust extraction system in our facility with the expert support from LEV Designs. We would 100% recommend LEV and I look forward to working with them again in the future", classes: "offset-md-3 offset-lg-0" }
    ];

    let reviewOutput = reviewList.map(m => (
        <ReviewItem key={m.key} clientName={m.clientName} title={m.title} message={m.message} logo={m.companyLogo} classes={m.classes} />
    ));

    return (
        <section className={classes.Reviews}>
            <div className="container">
                <div className="row">
                    <div className="col-10 offset-1 col-sm-8 offset-sm-2 col-md-12 offset-md-0 p-sm-0 p-md-2">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>What our clients have to say...</h2>
                            </div>
                            {reviewOutput}
                        </div>
                    </div> 
                </div>
            </div>
        </section>
    );
};

export default reviews;