import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

class ContactForm extends Component {
    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            fields: {},
            errors: {},
            isChecked: false,
            formSent: false
        }
     }

    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        const requiredText = "* Required.";
        const lettersText = "* Letters Only.";

        //Name
        if (!fields["firstname"]) {
           formIsValid = false;
           errors["firstname"] = requiredText;
        }
  
        if (typeof fields["firstname"] !== "undefined") {
           if (!fields["firstname"].match(/^[a-zA-Z ]+$/)) {
              formIsValid = false;
              errors["firstname"] = lettersText;
           }
           else {
                if (fields["firstname"].length > 50) {
                    formIsValid = false;
                    errors["firstname"] = "* 50 Character Limit.";
                }
            }
        }
   
        //Surname
        if (!this.props.fullWidth) {
            if (!fields["surname"]) {
                formIsValid = false;
                errors["surname"] = requiredText;
             }
       
             if (typeof fields["surname"] !== "undefined") {
                if (!fields["surname"].match(/^[a-zA-Z -]+$/)) {
                   formIsValid = false;
                   errors["surname"] = lettersText;
                }
                else {
                    if(fields["surname"].length > 50){
                        formIsValid = false;
                        errors["surname"] = "* 50 Character Limit.";
                    }
                }       
             }
        }

         //Phone
        if (!fields["phone"]) {
            formIsValid = false;
            errors["phone"] = requiredText;
         }
   
         if (typeof fields["phone"] !== "undefined") {
            if (!fields["phone"].match(/^\d+$/)) {
               formIsValid = false;
               errors["phone"] = "* Numbers Only.";
            }
            else {
                if (fields["phone"].length > 15) {
                    formIsValid = false;
                    errors["phone"] = "* 15 Character Limit.";
                } else {
                    if (fields["phone"].length < 11) {
                        formIsValid = false;
                        errors["phone"] = "* 11 Characters Minimum.";
                    }
                }
            }
         }

        //Email
        if (!fields["email"]) {
           formIsValid = false;
           errors["email"] = requiredText;
        }
  
        if (typeof fields["email"] !== "undefined") {
            const email = fields["email"].trim();
            // const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (email === '') {
                errors["email"] = requiredText;
                formIsValid = false;
            } else {
                //disable for now in case blocking form submits
                // if (!emailRegex.test(email)) {
                //     errors["email"] = '* Invalid Format.';
                //     formIsValid = false;
                // }
                if (email.length < 3) {
                    errors["email"] = '* Invalid Format.';
                    formIsValid = false;
                }
                else {
                    if (email.length > 100) {
                        errors["email"] = '* 100 Character Limit.';
                        formIsValid = false;
                    }
                }
            }

       }

       //Privacy
       if (!fields["privacy"]) {
            formIsValid = false;
            errors["privacy"] = requiredText;
        }

       this.setState({errors: errors});
       return formIsValid;
   }
    
   handleSubmit(e) {
       e.preventDefault();
        if (this.handleValidation()) {
            axios.post("https://formcarry.com/s/CB-HpQqsE7_", this.state.fields) //full URL on index.js as a default
                .then(response => {
                    if (response.status === 200)
                        this.setState({formSent: true});
                        //window.gtag('event', 'conversion', {'send_to': 'AW-298954391/nxI4CNqHifgCEJfdxo4B'});
                        //custom analytics event imported into Google Ads for conversions 
                        window.gtag('event', 'submit', {'event_category': 'form'});
                });
        }
    }

    handleChange(field, e){
        let fields = this.state.fields;
        fields[field] = e.target.value;
        
        let isChecked = this.state.isChecked;
        if (field === "privacy") {
            isChecked = !isChecked;
            fields[field] = isChecked;
        }
            
        this.setState({fields, isChecked: isChecked});
    }

    render () {
        const surname = !this.props.fullWidth ?
                            <div className={this.props.fullWidth ? "col-12" : "col-12 col-sm-6"}>
                                <label className="col-form-label" htmlFor="Surname">Surname:</label>
                                <input autoComplete="family-name" className="form-control" id="Surname" name="lastName" type="text" onChange={this.handleChange.bind(this, "surname")} value={this.state.fields["surname"] || ""} />
                                <span className={this.props.fullWidth ? "field-validation-error-dark" : "field-validation-error"} data-valmsg-for="Surname">{this.state.errors["surname"] || ""}</span>
                            </div> : null;

        const submittedDiv = this.state.formSent ? <div className="form-submit"><span>Form Submitted Successfully!</span></div> : null;

        return (
            <>
                {submittedDiv}
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className={this.props.fullWidth ? "col-12" : "col-12 col-sm-6"}>
                            <label className="col-form-label" htmlFor="Firstname">Firstname:</label>
                            <input autoComplete="given-name" className="form-control" id="Firstname" name="firstName" type="text" onChange={this.handleChange.bind(this, "firstname")} value={this.state.fields["firstname"] || ""} />
                            <span className={this.props.fullWidth ? "field-validation-error-dark" : "field-validation-error"} data-valmsg-for="Firstname">{this.state.errors["firstname"] || ""}</span>
                        </div>
                        {surname}
                        <div className={this.props.fullWidth ? "col-12" : "col-12 col-sm-6"}>
                            <label className="col-form-label" htmlFor="EmailAddress">Email Address:</label>
                            <input autoComplete="email" className="form-control" id="EmailAddress" name="email" type="text" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"] || ""} />
                            <span className={this.props.fullWidth ? "field-validation-error-dark" : "field-validation-error"} data-valmsg-for="EmailAddress">{this.state.errors["email"] || ""}</span>
                        </div>
                        <div className={this.props.fullWidth ? "col-12" : "col-12 col-sm-6"}>
                            <label className="col-form-label" htmlFor="MobileNumber">Contact Number:</label>
                            <input autoComplete="mobile tel" className="form-control" id="MobileNumber" inputMode="numeric" name="anotherInput" type="text" onChange={this.handleChange.bind(this, "phone")} value={this.state.fields["phone"] || ""} />
                            <span className={this.props.fullWidth ? "field-validation-error-dark" : "field-validation-error"} data-valmsg-for="MobileNumber">{this.state.errors["phone"] || ""}</span>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className={this.props.fullWidth ? "col-12" : "col-12 col-md-8 col-lg-12 col-xl-8"}>
                            <div className="form-check">
                                <div className="form-check-label d-inline-block">
                                    <input className="form-check-input" id="AgreedToPrivacyPolicy" name="AgreedToPrivacyPolicy" type="checkbox" value="true" onChange={this.handleChange.bind(this, "privacy")} /><input name="AgreedToPrivacyPolicy" type="hidden" value="false" />
                                    <label htmlFor="AgreedToPrivacyPolicy">I agree to the <NavLink to="/privacy" title="Privacy Policy" target="_blank">Privacy &amp; Data Protection Notice</NavLink></label>
                                </div>
                            </div>
                            <span className={this.props.fullWidth ? "field-validation-error-dark" : "field-validation-error"} data-valmsg-for="AgreedToPrivacyPolicy">{this.state.errors["privacy"] || ""}</span>
                        </div>
                        <div className={this.props.fullWidth ? "col-12" : "col-12 mt-3 col-md-4 mt-md-0 col-lg-12 mt-lg-3 col-xl-4 mt-xl-0 text-center"}>
                            <button type="submit" id="SubmitBtn" className={"btn btn-lg btn-warning" + (this.props.fullWidth ? " d-block w-100 mt-3" : "")}>GET A QUOTE</button>
                            <input type="hidden" name="_gotcha" />
                        </div>
                    </div>
                </form>
            </>
        )
    }
    
};

export default ContactForm;