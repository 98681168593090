import React, { Component } from 'react';
import Head from "../Head/Head";
import PropTypes from 'prop-types';

import Auxillary from '../../hoc/Auxillary/Auxillary';

class CookiePolicy extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" }, { key: 2, link: "/cookies", title: "Cookie Policy" }]);
        const images = require.context('../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./lev-ductwork-systems.jpg").default, alt: "LEV Ductwork Systems" }]);
    }

    render () {
        return (
            <Auxillary>
                <Head title={this.props.title} link={this.props.link} description={this.props.description} />
                <h1>Cookie Policy</h1>
                <p>We use cookies on this website to provide you with a better user experience. We do this by placing a small text file on your device / computer hard drive to track how you use the website, to record or log whether you have seen particular messages that we display, to keep you logged into the website where applicable, to display relevant adverts or content, referred you to a third party website.</p>
                <p>Some cookies are required to enjoy and use the full functionality of this website.</p>
                <p>The cookies used will be saved for specific time periods, where others may last indefinitely. Your web browser should provide you with the controls to manage and delete cookies from your device, please see your web browser options.</p>
            </Auxillary>
        )
    }
    
};

CookiePolicy.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default CookiePolicy;