import React from 'react';

import classes from './CallToAction.module.css';
import LinkButton from '../../UI/LinkButton/LinkButton';

const callToAction = () => (
    <section className={classes.CallToAction}>
        <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <h2>Schedule LEV testing or build a bespoke ventilation solution</h2>
                    <p>Call <LinkButton link="tel:01617631937" title="Find Out More" classList="btn btn-lg btn-warning-outline m-1 mt-md-0 telno">0161 763 1937</LinkButton> and speak to the team or <LinkButton title="Chat To Us" classList="btn btn-lg btn-warning m-1 mt-md-0" link="/contact">SCHEDULE A CALL</LinkButton></p>
                </div>
            </div>
        </div>
    </section>
);

export default callToAction;