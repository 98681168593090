import React from 'react';

import Image from '../../../UI/Image/Image';
import reviewStarsImage from '../../../../assets/images/5-star-rating.png';

const reviewItem = (props) => {

    let formClasses = "col-12 col-md-6 col-lg-4 mb-4";

    if (props.classes != null)
        formClasses += " " + props.classes;

    return (
        <div className={formClasses}>
            <div className="card">
                <div className="card-header">
                    <Image src={reviewStarsImage} alt="Review Stars" classList={["img-fluid"]}/>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="offset-2 col-8 offset-sm-0 col-sm-4 offset-lg-0 col-lg-4">
                            <Image src={props.logo} alt="Review Logo" classList={["img-fluid", "img-fully-rounded"]}/>
                        </div>
                        <div className="mt-3 col-12 mt-sm-0 col-sm-8 col-lg-8">
                            <h4 className="mb-2 logo-green">{props.clientName}</h4>
                            <h3 className="card-title">{props.title}</h3>
                        </div>
                        <div className="col-12">
                            <hr />
                            <p className="card-text">{props.message}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default reviewItem;