import React, { Component } from 'react';
import Head from "../Head/Head";

import Auxillary from '../../hoc/Auxillary/Auxillary';

class NotFound extends Component {

    componentDidMount() {
        this.props.setLocation([{ key: 1, link: "/", title: "Home" }]);
        const images = require.context('../../assets/images', true);
        this.props.setMainImage([{ key: 1, src: images("./dust-ventilation-system.jpg").default, alt: "Bespoke LEV Extraction System" }]);
    }

    render () {
        return (
            <Auxillary>
                <Head title={this.props.title} />
                <h1>404 - Not Found</h1>
                <p>We're sorry but this page does not exist. Please return to the <a href="/" title="Home">home page</a> to continue.</p>
                <p>Thank you.</p>
            </Auxillary>
        )
    }
    
};

export default NotFound;